import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ParameterContext } from '../context/ParameterContext';
import { parse, stringify } from 'query-string';

export const useParameter = () => {
    const location = useLocation();
    const {
        partnerId,
        collectionId,
        setPartnerId,
        setCollectionId 
    } = useContext(ParameterContext);

    const [allParams, setAllParams] = useState({});

    // url first
    useEffect(() => {
        const search = location.search;
        const params = parse(search);
        setAllParams(params);

        const filter = params.filter;
        // user's filter first
        if (filter) {

        }

        if (params.partner_id != null && params.partner_id !== partnerId) {
            setPartnerId(params.partner_id)
        }
        if (params.collection_id != null && params.collection_id !== collectionId) {
            setCollectionId(params.collection_id)
        }
    }, [location.search, partnerId, collectionId, setPartnerId, setCollectionId])

    return {
        partnerId,
        collectionId,
        setPartnerId,
        setCollectionId,
        search: stringify({
            partner_id: partnerId,
            collection_id: collectionId,
        }),
        allParams,
    };
}
