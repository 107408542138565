import * as React from 'react';
import { 
    List, 
    EditButton, 
    CreateButton, 
    ExportButton, 
    TopToolbar, 
    Datagrid, 
    TextField, 
    NumberField,
    TextInput,
} from 'react-admin';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { LongTextField } from '../common/LongTextField';
import { Pagination } from '../common/Pagnation';

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const CollectionButton = ({ record, basePath, resource }) => {
    return (
        <Button
            startIcon={<RemoveRedEyeIcon />}
            color="primary"
            component={Link}
            to={{ 
                pathname: `/collection`,
                search: `?partner_id=${record.id}`,
            }}
        >
            Collections
        </Button>
    )
}

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

export const PartnerList = (props) => {
    return (<List 
        syncWithLocation={false}
        filters={postFilters}
        {...props} 
        actions={<ListActions/>} 
        bulkActionButtons={false}
        pagination={<Pagination />}
        >
        <Datagrid>
            <NumberField sortable={false} label="ID" source="id" />
            <TextField sortable={false} label="Name" source="name" />
            <LongTextField sortable={false} label="Description" source="description" />
            <EditButton />
            <CollectionButton />
        </Datagrid>
    </List>)
};

