import React from 'react';
import TextField from '@material-ui/core/TextField';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import blue from '@material-ui/core/colors/blue';
import ChipInput from 'material-ui-chip-input'

import { Field } from 'react-final-form';

export const MuiField = ({ type, name, ...props }) => {
    return <Field name={name} type={type} {...props}>
        {props => {
            return <div className={props.className}>
                <TextField
                    {...props}
                    inputProps={{
                        min: 0,
                    }}
                    type={props.input.type || 'text'}
                    className="metadata-edit-input"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={(e) => {
                        props.onChange && props.onChange(e);
                        props.input.onChange(e);
                    }}
                    label={props.label}
                    disabled={props.disabled}
                />
            </div>
        }}
    </Field>;
}


export const MuiSelect = ({ className, name, values, label, }) => {
    return <Field name={name} className={className} label={label}>
        {props => {
            return <div className={props.className}>
                <InputLabel id={`metadata-select-helper-${name}`}>{props.label}</InputLabel>
                <SelectField
                    variant="outlined"
                    labelId={`metadata-select-helper-${name}`}
                    className="metadata-edit-select"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    label={props.label}
                    disabled={props.disabled}
                >
                    {
                        values.map((v, i) => {
                            return <MenuItem key={`${v.name}-${i}`} value={v.value}>{v.name}</MenuItem>
                        })
                    }
                </SelectField>
            </div>
        }}
    </Field>;
}

export const CustomChipRenderer = (customHandleClick) => ({ value, text, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => {
    return <Chip
      key={key}
      className={className}
      style={{
        pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
        backgroundColor: isFocused ? blue[300] : undefined
      }}
      onClick={(e) => customHandleClick(key)}
      onDelete={handleDelete}
      label={text}
    />
}

export const MuiChips = ({name, ...props}) => {
    return <Field name={name} {...props}>
        {(props) => <ChipInput
            chipRenderer={CustomChipRenderer((key) => {
                // trick...
                props.input.value.splice(key, 1);
            })}
            className={props.className}
            name={props.input.name}
            value={props.input.value || []}
            placeholder="Input possible traits of this trait type"
            onMouseDown={e => e.stopPropagation()}
            onAdd={(e) => {
                props.input.value.push(e);
            }}
            onDelete={(e, i) => {
                props.input.value.splice(i, 1);
            }}
        />}
    </Field>
}


