import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Inbox from '@material-ui/icons/Inbox';
import Add from '@material-ui/icons/Add';
import {
    useTranslate,
    useListContext,
    useResourceContext,
    useGetResourceLabel,
} from 'ra-core';
import { Link } from 'react-router-dom';

import { useParameter } from '../hooks/useParameter';

export const Empty = (props) => {
    const { basePath, hasCreate } = useListContext(props);
    const resource = useResourceContext(props);
    const classes = useStyles(props);
    const translate = useTranslate();
    const { partnerId, } = useParameter();


    const getResourceLabel = useGetResourceLabel();
    const resourceName = translate(`resources.${resource}.forcedCaseName`, {
        smart_count: 0,
        _: getResourceLabel(resource, 0),
    });

    const emptyMessage = translate('ra.page.empty', { name: resourceName });
    const inviteMessage = translate('ra.page.invite');

    return (
        <>
            <div className={classes.message}>
                <Inbox className={classes.icon} />
                <Typography variant="h4" paragraph>
                    {translate(`resources.${resource}.empty`, {
                        _: emptyMessage,
                    })}
                </Typography>
                {hasCreate && (
                    <Typography variant="body1">
                        {translate(`resources.${resource}.invite`, {
                            _: inviteMessage,
                        })}
                    </Typography>
                )}
            </div>
            {hasCreate && (
                <div className={classes.toolbar}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={{ 
                            pathname: `${basePath}/create`,
                            search: `?partner_id=${partnerId}`
                        }}
                        startIcon={<Add />}
                    >
                        Create
                    </Button>
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'RaEmpty' }
);
