import React, { useState, } from 'react';
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import { Loading } from 'react-admin';

import "./index.css";

import { 
    useGetNftAudit, 
} from '../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    height: 600,
    overflowY: 'scroll',
    background: '#fff',
    boxShadow: 24,
    p: 4,
};


export const NftLogsBlock = ({ id }) => {
    const getNftAudit = useGetNftAudit();
    const [open, setOpen] = useState(false);

    const data = getNftAudit.data;

    return (
        <Box className="metadata-block">
            <Button
                color="primary"
                onClick={() => {
                    getNftAudit.sendRequest(id);
                    setOpen(true);
                }}
            >
                logs
            </Button>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <TableContainer component={Box} style={style}>
                    {getNftAudit.loading ? <Loading loadingSecondary="" /> :
                        <Table sx={{ minWidth: 400 }} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Result</TableCell>
                                <TableCell>Created by</TableCell>
                                <TableCell>Created time</TableCell>
                                <TableCell>Updated by</TableCell>
                                <TableCell>Updated time</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.action}</TableCell>
                                    <TableCell>{row.result}</TableCell>
                                    <TableCell>{row.create_by}</TableCell>
                                    <TableCell>{row.create_time}</TableCell>
                                    <TableCell>{row.update_by}</TableCell>
                                    <TableCell>{row.update_time}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    </TableContainer>
            </Modal>
        </Box>
    );
};