import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
    List,
    Loading,
    FunctionField,
    TopToolbar,
    Datagrid,
    TextField,
    TextInput,
    useUnselectAll,
    useRefresh,
    useListContext,
} from 'react-admin';
import {
    useWeb3React
} from '@web3-react/core'
import { useConfirm } from 'material-ui-confirm';

import { Button } from '@material-ui/core';
import { TxLink, AddressLink } from '../common/EthscanLink';
import { useUserInfo } from '../hooks/useUserInfo'
import { useParameter } from '../hooks/useParameter'
import { useNotification } from '../hooks/useNotification'
import { MetadataBlock } from './MetadataBlock';
import { NftLogsBlock } from './NftLogsBlock';

import { OrderBlock } from './OrderBlock';
import {
    useApproveNftReview,
    useRequestNftReview,
    useMintNft,
    useGetDistribution,
} from '../api';
import { useCreateNftContract, } from '../hooks/useNftContract';
import { Filter } from '../common/Filter';

import "./index.css";
import { Operations } from './Operations';
import { ConfirmDescription } from './ConfirmDescription';
import { networkConfigs } from '../common/network';
import { useCollection } from '../hooks/useCollection';
import { Pagination } from '../common/Pagnation';
import { friendlifyMessage } from '../common/friendlyMessage';

const ListActions = ({ 
    filterIndex, 
    handleSelectReview, 
    handleSelectApprove, 
    handleMint, 
    handleDeploy,
    collectionId, 
    partnerId,
    templateId,
    distribution,
}) => {
    const { isAdmin } = useUserInfo();
    return <TopToolbar id="list-actions-toolbar">
        <Operations
            distribution={distribution}
            isAdmin={isAdmin}
            filterIndex={filterIndex}
            handleSelectReview={handleSelectReview}
            handleSelectApprove={handleSelectApprove}
            handleMint={handleMint}
            handleDeploy={handleDeploy}
            partnerId={partnerId}
            templateId={templateId}
            collectionId={collectionId}
        />
    </TopToolbar>
};

// bulk
const BulkActionButtons = (props) => {
    const [selectedData, setSelectedData] = useState({});
    const { data } = useListContext();

    useEffect(() => {
        const currentDataCount = Object.keys(selectedData).length;
        if (currentDataCount === props.selectedIds.length) {
            console.log(`selected data synced!`)
            return;
        }
        console.log(`new items selected: ${props.selectedIds.length - currentDataCount}`);
        setSelectedData(props.selectedIds.reduce((target, id) => {
            if (selectedData[id]) {
                target[id] = selectedData[id];
            } else {
                target[id] = data[id];
            }
            return target;
        }, {}));
    }, [props.selectedIds, data, selectedData]);

    return <Fragment>
        {/* <ResetViewsButton label="Reset Views" {...props} /> */}
        {/* <BulkDeleteButton {...props} /> */}
        <Button
            variant="contained"
            disabled={props.loading}
            color="primary"
            onClick={() => props.handleBulkSubmitAction(selectedData)}
        >
            {props.actionName}
        </Button>
    </Fragment>
};


const RowStyleFilters = [
    // try to review
    (record, index) => record.status === 'Init_OK' || record.status === 'Review_Failed',
    // try to approve
    (record, index) => record.status === 'Review_OK' || record.status === 'Approve_Failed',
    // try to mint
    (record, index) => {
        return (record.status === 'Approve_OK' || record.status === 'Mint_Failed') && !!record.recipient_address;
    },
];

const MINT = 2;
const APPROVE = 1;
const REQUEST_REVIEW = 0;

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

export const NftsList = (props) => {
    const [, sendNotification] = useNotification();
    const refresh = useRefresh();
    const { account, chainId, } = useWeb3React();
    const unselectAll = useUnselectAll();
    const { deploy } = useCreateNftContract();
    const confirm = useConfirm();


    const [actionName, setActionName] = useState('');
    const [filterIndex, setFilterIndex] = useState(-1);
    const { collectionId, partnerId, setPartnerId, } = useParameter();
    const [loading, setLoading] = useState(true);
    const [distribution, setDistribution] = useState(null);

    const handleRequestSuccess = () => {
        setFilterIndex(-1);
        refresh();
        unselectAll('nft');
    };

    const handleReset = useCallback(() => {
        setFilterIndex(-1);
        unselectAll('nft');
    }, [unselectAll])

    //
    const requestReviewQuery = useRequestNftReview({
        onSuccess: handleRequestSuccess
    });
    const approveNftReviewQuery = useApproveNftReview({
        onSuccess: handleRequestSuccess
    });
    const mintNftQuery = useMintNft({
        onSuccess: () => {
            sendNotification({ msg: 'Minting NFTs success!', variant: 'success', });
            handleRequestSuccess();
        }
    });

    const distributionQuery = useGetDistribution();
    const { sendRequest: sendDistributionRequest, data: distributionData, } = distributionQuery;

    const { 
        collection: collectionData,
        updateCollection,
        getCollectionLoading,
        getCollection,
    } = useCollection();

    // reset list when leaving or redirecting, otherwise bulk action won't fold
    useEffect(() => {
        return () => {
            handleReset();
        }
    }, [handleReset]);


    useEffect(() => {
        if (collectionId) {
            getCollection(collectionId);
            sendDistributionRequest(collectionId);
        }
    }, [collectionId, getCollection, sendDistributionRequest]);

    
    useEffect(() => {
        if (collectionData) {
            setPartnerId(collectionData.partner_id);
        }
        if (distributionData) {
            setDistribution(distributionData);
        }
    }, [collectionData, distributionData, setPartnerId])


    // 
    useEffect(() => {
        setLoading(
            requestReviewQuery.loading
                || approveNftReviewQuery.loading
                || mintNftQuery.loading
                || distributionQuery.loading
                || getCollectionLoading,
        );
    }, [
        requestReviewQuery.loading,
        approveNftReviewQuery.loading,
        mintNftQuery.loading,
        distributionQuery.loading,
        getCollectionLoading,
    ]);

    //
    const handleSelectReview = () => {
        setFilterIndex(filterIndex === REQUEST_REVIEW ? -1 : REQUEST_REVIEW);
        setActionName('Request review');
    };

    const handleSelectApprove = () => {
        setFilterIndex(filterIndex === APPROVE ? -1 : APPROVE);
        setActionName('Approve');
    };

    const handleMint = () => {
        setFilterIndex(filterIndex === MINT ? -1 : MINT);
        setActionName('Mint');
    };

    const handleBulkSubmitAction = async (selectedData) => {
        if (filterIndex === MINT) {
            await handleActualMint(selectedData);
        }
        else {
            await confirm({
                content: <ConfirmDescription
                    selectedData={selectedData}
                />,
                allowClose: true,
            }).then(() => {
                const sendRequest = [
                    requestReviewQuery,
                    approveNftReviewQuery,
                ][filterIndex].sendRequest;
                return sendRequest(Object.keys(selectedData).map(id => parseInt(id, 10)));
            }).catch(() => {
                console.log(`error action: ${filterIndex}`);
            });
        }
    };

    const handleDeploy = async () => {
        const result = await deploy({
            baseUri: collectionData.base_uri,
            totalSuppy: collectionData.total_supply,
            name: collectionData.name,
            symbol: collectionData.symbol,
            templateId: collectionData.contract_template_id,
        });

        if (!result.address || !result.createTx) {
            sendNotification({ msg: 'Something is wrong with deploying contract', variant: 'error', });
            return;
        }

        // update collection
        console.log('chain name', networkConfigs[chainId].shortname, result);
        await handleUpdateCollection({
            contract_addr: result.address,
            create_tx: result.createTx,
            chain: networkConfigs[chainId].shortname,
        });
        return result.address;
    }

    // update collection and retrieve data
    const handleUpdateCollection = async (incrementCollection) => {
        const newCollection = {
            ...collectionData,
            ...incrementCollection,
        }
        await updateCollection(newCollection);
    }

    // mint is special
    const handleActualMint = async (selectedData) => {
        if (!collectionData) {
            sendNotification({ msg: 'Collection data not fetched, waiting for a sec', variant: 'error' });
            return;
        }
        // precheck
        if (!account) {
            sendNotification({ msg: 'Wallet not connected', variant: 'error' });
            return;
        }
        if (!collectionData) {
            sendNotification({ msg: 'Get collection data error', variant: 'error' });
            return;
        }

        let contractAddress = collectionData.contract_addr;
        if (!contractAddress) {
            sendNotification({ msg: 'There is no deployed contract for this collection, creating one...' });
            contractAddress = await handleDeploy();
        }

        const { contract_template_id: templateId } = collectionData;
        await confirm({
            description: <ConfirmDescription subText="will recieve" selectedData={selectedData} />,
            allowClose: true,
            confirmationButtonProps: { autoFocus: false },
        }).then(() => {
            const ids = Object.keys(selectedData).map(id => parseInt(id, 10));
            const recipients = ids.map(id => selectedData[id].recipient_address);
            const tokenIds = ids.map(id => selectedData[id].token_id);
            sendNotification({ msg: 'Minting NFTs...', });
            return mintNftQuery.mint(recipients, tokenIds, ids, contractAddress, templateId);
        }).catch((e) => {
            console.log(`cancelled`);
            sendNotification({ msg: 'Minting error: ' + friendlifyMessage(e.message), variant: 'error' });
            handleReset();
        });
    }

    if (!collectionId) {
        return <Filter name="collection_id" label="Collection ID" />
    }

    if (loading) {
        return <Loading />;
    }

    return (<Fragment>
        <List
            {...props}
            className="nft-list"
            filters={postFilters}
            title={<div>Collection {collectionId} {chainId}</div>}
            actions={
                <ListActions
                    filterIndex={filterIndex}
                    handleSelectReview={handleSelectReview}
                    handleSelectApprove={handleSelectApprove}
                    handleMint={handleMint}
                    handleDeploy={handleDeploy}
                    collectionId={collectionId}
                    partnerId={partnerId}
                    templateId={collectionData.contract_template_id}
                    distribution={distribution}
                />}
            bulkActionButtons={
                filterIndex >= 0
                    ? <BulkActionButtons
                        actionName={actionName}
                        handleBulkSubmitAction={handleBulkSubmitAction}
                        loading={loading}
                    />
                    : false
            }
            filterDefaultValues={{
                collection_id: collectionId,
            }}
            syncWithLocation={false}
            pagination={<Pagination />}
        >
            <Datagrid isRowSelectable={RowStyleFilters[filterIndex]}>
                <TextField sortable={false} label="ID" source="id" />
                <FunctionField sortable={false}
                    label="Order"
                    render={record => record.order ? <OrderBlock {...record.order} /> : <div className="text9">None</div>}
                />
                <AddressLink source="recipient_address" title="Recipient" chain={collectionData.chain} />
                <FunctionField sortable={false}
                    label="Metadata"
                    render={record => <MetadataBlock distribution={distribution} {...record.metadata} nft_id={record.id} mint_tx={record.mint_tx} />}
                />
                <TxLink source="mint_tx" title="Mint transaction" chain={collectionData.chain} />
                <TextField sortable={false} label="Token ID" source="token_id" />
                <TextField sortable={false} label="Status" source="status" />
                <FunctionField sortable={false}
                    label="Audit Logs"
                    render={record => <NftLogsBlock id={record.id} />}
                />
            </Datagrid>
        </List>
    </Fragment>)
};