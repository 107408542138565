import React, { useEffect, useState, Fragment, } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Loading } from 'react-admin';
import { useConfirm } from 'material-ui-confirm';

import { EditFooter } from './EditFooter';
import { MuiField, MuiSelect } from './FinalForms';
import { PredefinedInputArray } from './PredefinedInputArray';

import { 
  useGetMetadataConfig, 
  useGetMetadataTraits,
  useCreateMetadataConfig,
  useGenerateNFT,
} from '../api';

import { useNotification } from '../hooks/useNotification';
import { RandomInputArray } from './RandomInputArray';

import './index.css';

// default data patchers
const getDefaultPredefinedTraits = (traitDictionary) => {
  const traitsType = Object.keys(traitDictionary);
  const traits = traitsType.map((type) => {
      return {
          type,
          value: traitDictionary[type][0],
      }
  });
  return [{
    quantity: 10,
    traits,
  }];
}

const getDefaultRandomTraits = (traitDictionary) => {
  const traitsType = Object.keys(traitDictionary);
  const quantity = 1;
  const traits = traitsType.reduce((target, type) => {
    const values = traitDictionary[type];
    return [
      ...target,
      ...values.map(value => ({
        quantity,
        traits: [{
          type, value,
        }]
      }))
    ]
  }, [])
  return traits;
}

// when new traits are added, we need to patch them into the values
const patchRandomConfig = (traitDictionary, traitConfig, oldTraitIndexMap) => {
  const newTraitConfig = [];
  Object.keys(traitDictionary).forEach((key) => {
    // find key
    traitDictionary[key].forEach(value => {
      const traitIndex = oldTraitIndexMap[key] && oldTraitIndexMap[key][value];
      if (traitIndex != null) {
        newTraitConfig.push(traitConfig[traitIndex]);
      } else {
        newTraitConfig.push({
          quantity: 1, traits: [{
            type: key, value,
          }]
        });
      }
    })
  });
  return newTraitConfig;
}

const patchPredefinedConfig = (traitDictionary, traitConfig) => {
  const traitTypes = Object.keys(traitDictionary);
  // 肯定会至少有一个，取第一个作为样本拿到 traits map
  const lastSavedTraitIndexMap = traitConfig[0].traits.reduce((target, { type, value }, index) => {
    target[type] = index;
    return target;
  }, {});

  const newTraitConfig = traitConfig.map(({ quantity, traits }) => {
    const newTraits = traitTypes.map(type => {
      const oldIndex = lastSavedTraitIndexMap[type];
      return {
        type,
        value: oldIndex != null ? traits[oldIndex].value : traitDictionary[type][0],
      }
    });
    return { traits: newTraits, quantity };
  });
  

  return newTraitConfig;
}

// traits index to locate the random data entry 
const makeRandomTraitIndex = (traitConfig) => {
  return traitConfig.reduce((target, { quantity, traits }, index) => {
    const { type, value } = traits[0];
    if (!target[type]) {
      target[type] = {};
    }
    if (!target[type][value]) {
      target[type][value] = {};
    }
    target[type][value] = index;
    return target;
  }, {})
}

export const MetadataGenerationConfig = ({
  id: collectionId,
  isEdit,
  activeStep,
  onBack,
  onNext,
  backText,
  nextText,
}) => {
  const metadataTraitQuery = useGetMetadataTraits();
  const createMetadataConfigQuery = useCreateMetadataConfig();
  const metadataConfigQuery = useGetMetadataConfig();
  const [, sendNotification] = useNotification();
  const confirm = useConfirm();

  const generateNFT = useGenerateNFT({
    onSuccess: () => {
      onNext();
    },
  });

  const [loading, setLoading] = useState(true);
  const [defaultPredefinedTraits, setDefaultPredefinedTraits] = useState([]);
  const [traitIndexMap, setTraitIndexMap] = useState(null);

  // input temp values
  let inputValues = {};
  const [initialValues, setInitialValues] = useState(null);


  useEffect(() => {
    setLoading(
      metadataConfigQuery.loading
        || metadataTraitQuery.loading
        || createMetadataConfigQuery.loading
        || generateNFT.loading
    );
  }, [
    collectionId,
    metadataConfigQuery.loading, 
    metadataTraitQuery.loading, 
    createMetadataConfigQuery.loading,
    generateNFT.loading
  ])

  // predefined和random设置两份数据，方便切换
  useEffect(() => {
    if (metadataConfigQuery.loading || metadataTraitQuery.loading ) {
      return;
    }
    const traitDictionary = metadataTraitQuery.traitDictionary;
    if (!traitDictionary) {
      return;
    }
    const defaultPredefinedTraits = getDefaultPredefinedTraits(traitDictionary);
    const defaultRandomConfigTraits = getDefaultRandomTraits(traitDictionary);
    setDefaultPredefinedTraits(defaultPredefinedTraits);
    // setDefaultRandomConfigTraits(defaultRandomConfigTraits);

    // data has returned, but no config yet
    const metadataConfig = metadataConfigQuery.data || {
      id: 0,
      combo_type: 'random',
      traits_config: [],
    }

    if (!metadataTraitQuery.data || !metadataTraitQuery.data.length) {
      return;
    }
  
    const { id, combo_type, traits_config } = metadataConfig;
    if (id === 0) {
      console.log(`metadata empty, will use user generated data`);
    }

    const isRandom = combo_type === 'random';
          
    const randomConfig = (isRandom && traits_config.length ) ? traits_config : defaultRandomConfigTraits;
    const predefinedConfig = (!isRandom && traits_config.length) ? traits_config : defaultPredefinedTraits;

    // 没经过 patch 的 indexmap
    const oldTraitIndexMap = makeRandomTraitIndex(randomConfig);

    // add trait index map, if there are new traits
    const patchedRandomTraitsConfig = patchRandomConfig(metadataTraitQuery.traitDictionary, randomConfig, oldTraitIndexMap);
    const patchedPredefinedTraitsConfig = patchPredefinedConfig(metadataTraitQuery.traitDictionary, predefinedConfig);

    setInitialValues({
      id, combo_type, collection_id: collectionId,
      traits_config: {
        random: patchedRandomTraitsConfig,
        predefined: patchedPredefinedTraitsConfig,
      },
    });

    const patchedTraitIndexMap = makeRandomTraitIndex(patchedRandomTraitsConfig);
    setTraitIndexMap(patchedTraitIndexMap);
  }, [
    collectionId, 
    metadataConfigQuery.data, 
    metadataTraitQuery.data,
    metadataTraitQuery.traitDictionary, 
    metadataConfigQuery.loading, 
    metadataTraitQuery.loading,
  ]);


  // prefetch traits list
  const { sendRequest: sendMetadataConfigRequest } = metadataConfigQuery;
  const { sendRequest: sendMetadataTraitRequest } = metadataTraitQuery;
  useEffect(() => {
    sendMetadataConfigRequest(collectionId);
    sendMetadataTraitRequest(collectionId);
  }, [collectionId, sendMetadataConfigRequest, sendMetadataTraitRequest]);

  const validatePair = ({type, value}) => {
    const traitDictionary = metadataTraitQuery.traitDictionary;
    if (!traitDictionary[type]) {
      console.log(`invalid value - type: ${type}, value: ${value}`)
      return false;
    }
    if (!traitDictionary[type].includes(value)) {
      console.log(`invalid value - type: ${type}, value: ${value}`)
      return false;
    }
    return true;
  }

  const validate = (values) => {
    let valid = true;
    const traitsConfig = values.traits_config[values.combo_type];
    traitsConfig.forEach(({ traits, quantity }) => {
      traits.forEach(({ type, value }) => {
        valid = valid && validatePair({ type, value });
      });
    });
    return valid;
  }

  if (loading || !initialValues || !traitIndexMap) {
    return <Loading />;
  }

  return (
    <Box>
      <Form
        onSubmit={() => { }}
        initialValues={initialValues}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators
        }}
        render={({ handleSubmit, pristine, invalid, values }) => {
          inputValues = values;
          const isRandom = values.combo_type === 'random';
          return <form onSubmit={handleSubmit} className="metadata-traits-edit-form">
            <MuiField className="metadata-trait-edit-form-item" name="collection_id" label="Collection ID" disabled />
            <MuiSelect className="metadata-trait-edit-form-item" name="combo_type" label="Combo Type" values={[
              { name: 'Random', value: 'random' },
              { name: 'Predefined', value: 'predefined' },
            ]} />
            {isRandom && <RandomInputArray
              name={`traits_config.${values.combo_type}`}
              traitDictionary={metadataTraitQuery.traitDictionary}
              isRandom={isRandom}
              traitIndexMap={traitIndexMap}
            />}
            {!isRandom && 
              <FieldArray name={`traits_config.${values.combo_type}`}>
                {({ fields }) => {
                  return <div className="metadata-trait-edit-form-item">
                    <label>Traits setting</label>
                    {fields.map((name, index) => (
                      // each edit row
                      <div key={name} className="metadata-config-edit-row">
                        <span className="metadata-config-edit-row-index">{index}</span>
                        {/* inner field arry, traitsDict */}
                        <div className="metadata-config-edit-row-left">
                          <Fragment>
                            <MuiField name={`${name}.quantity`} label="Quantity" type="number" />
                            <PredefinedInputArray
                              name={`${name}.traits`}
                              traitDictionary={metadataTraitQuery.traitDictionary}
                            />
                          </Fragment>
                        </div>
                        <Button
                          color="primary"
                          startIcon={<RemoveIcon />}
                          className="metadata-trait-remove-button metadata-trait-remove-outer-button"
                          onClick={() => fields.remove(index)}>
                          Remove
                        </Button>
                      </div>
                    ))}
                    <Button
                      color="primary"
                      className="metadata-trait-add-button"
                      startIcon={<AddIcon />}
                      type="button"
                      onClick={() => {
                        // push default values for `traits_config`
                        if (defaultPredefinedTraits.length) {
                          console.log('push new values')
                          fields.push(defaultPredefinedTraits[0]);
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                }}
              </FieldArray>
            }
          </form>
        }}
      />

      {/* footer */}
      <EditFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={onBack}
          sx={{ mr: 1 }}
        >
          {backText}
        </Button>
        <Button 
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={async () => {
              // set this so we can keep the values not be flushed
              setInitialValues(inputValues);

              if (!validate(inputValues)) {
                sendNotification({ msg: 'Please fill the empty values', variant: 'error', })
                return;
              }
              await createMetadataConfigQuery.sendRequest({
                ...inputValues,
                traits_config: inputValues.traits_config[inputValues.combo_type],
              });
              await confirm({
                description: 'This action will overwrite all existing NFTs.',
                allowClose: true,
              }).then(() => {
                setInitialValues(inputValues);
                generateNFT.sendRequest(collectionId);
              }).catch(() => {
                console.log(`cancelled`);
              });
            }}>
            {nextText}
        </Button>
      </EditFooter>
    </Box>
  )
};