import * as React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, useRedirect, required } from 'react-admin';


export const PartnerCreate = (props) => {
    const redirect = useRedirect();
    return (<Create onSuccess={() => {
        redirect('/partner');
    }} {...props}>
        <SimpleForm>
            <NumberInput disabled label="ID" source="id" />
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Description" source="description" validate={required()} />
        </SimpleForm>
    </Create>);
};
