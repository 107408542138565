import * as React from "react";
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin';


export const PartnerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField disabled label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Description" source="description" />
        </SimpleShowLayout>
    </Show>
);