import {
    InjectedConnector
} from '@web3-react/injected-connector';

export const userSetChainId = process.env.REACT_APP_CHAIN_ID;

export const connectorChainIdStorageKey = 'CONNECTOR_STORAGE_KEY';

export const networkConfigs = {
    1: {
        chainId: 1,
        rpcUrl: 'https://mainnet.infura.io/v3/',
        blockExplorerUrl: 'https://etherscan.io',
        name: 'Ethereum Mainnet',
        shortname: 'eth',
        nativeCurrency: 'ETH',
    },
    137: {
        chainId: 137,
        rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
        blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com',
        name: 'Matic Mainnet',
        shortname: 'polygon',
        nativeCurrency: 'MATIC',
    },
    // 42: {
    //   rpcUrl: 'https://kovan.infura.io/v3/',
    //   blockExplorerUrl: 'https://kovan.etherscan.io',
    //   name: 'Kovan Test Network',
    //   nativeCurrency: 'ETH',
    // },
    4: {
        chainId: 4,
        rpcUrl: 'https://rinkeby.infura.io/v3/',
        blockExplorerUrl: 'https://rinkeby.etherscan.io',
        name: 'Rinkeby Testnet',
        shortname: 'rinkeby',
        nativeCurrency: 'ETH',
    },
    // 97: {
    //   rpcUrl: 'https://data-seed-prebsc-2-s1.binance.org:8545',
    //   blockExplorerUrl: 'https://testnet.bscscan.com',
    //   name: 'Binance Smart Chain Testnet',
    //   nativeCurrency: 'BNB',
    // },
    // 80001: {
    //   rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    //   blockExplorerUrl: 'https://mumbai.polygonscan.com/',
    //   name: 'Matic Testnet Mumbai',
    //   nativeCurrency: 'tMATIC',
    // },
}

export const chainIdNetworkConfigMap = {
    'eth': networkConfigs[1],
    'rinkeby': networkConfigs[4],
    'polygon': networkConfigs[137],
};

export const getCachedChainId = () => {
    return Number(window.localStorage.getItem(connectorChainIdStorageKey));
}

export const setCachedChainId = (chainId) => {
    window.localStorage.setItem(connectorChainIdStorageKey, chainId)
}

export const defaultChainId =
    userSetChainId ||
    getCachedChainId() ||
    (process.env.NODE_ENV === 'development' ? 4 : 137);

export const injectedConnector = new InjectedConnector({
    supportedChainIds: Object.keys(networkConfigs).map(i => parseInt(i, 10))
});

export const setupNetwork = async (newChainId) => {
    const provider = window.ethereum;
    if (provider) {
        try {
            const result = await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: `0x${newChainId.toString(16)}`
                }],
            });
            console.log(`switching network, ${newChainId}...`, result)
            return true;
        } catch (e) {
            console.log(`switching failed,  add network ${newChainId}...`)
            try {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: `0x${newChainId.toString(16)}`,
                        chainName: networkConfigs[newChainId].name,
                        nativeCurrency: {
                            name: 'MATIC',
                            symbol: 'matic',
                            decimals: 18,
                        },
                        rpcUrls: [networkConfigs[newChainId].rpcUrl],
                        blockExplorerUrls: [networkConfigs[newChainId].blockExplorerUrl],
                    }, ],
                });
                return true;
            } catch (error) {
                console.error('Failed to setup the network in Metamask:', error);
                return false;
            }
        }
    } else {
        console.error("Can't setup the network on metamask because window.ethereum is undefined");
        return false;
    }
};