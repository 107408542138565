import React from 'react';
import { MuiSelect, } from './FinalForms';
import { Field } from 'react-final-form';


export const PredefinedInputArray = ({ name, traitDictionary }) => {
    return (
      <div className="predefined-input-array">
        <Field name={name}>
          {/* values: array of row */}
          {(props) => {
            const { value: values, } = props.input;
            let inputs = values.map(({type, value}, index) => {
            const options = traitDictionary[type]
              ? traitDictionary[type].map(v => {
                return {
                  name: v,
                  value: v,
                }
              }) : [];
              return (
                <div key={`${name}-${index}`} className="metadata-traits-edit-inner">
                    <MuiSelect name={`${name}[${index}].value`} label={type} values={options} />
                </div>
              );
            });
            return inputs;
          }}
  
        </Field>
        </div>
    )
  }
  