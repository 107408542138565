import React from 'react';
import { Button, } from '@material-ui/core';
import {
  useWeb3React
} from '@web3-react/core'

import { networkConfigs, chainIdNetworkConfigMap } from '../network';
import { useCollection } from '../../hooks/useCollection';
import { useWeb3 } from '../../hooks/useWeb3';
import { parseAbi } from './parseAbi';
import { SingleForm } from './SingleForm';

export const ContractForm = ({
  abi,
  handleDeploy,
  onNotification,
}) => {
  const { account, chainId, } = useWeb3React();
  const { collection, updateCollection } = useCollection();
  const web3 = useWeb3();

  const parsedAbi = parseAbi(abi);

  if (!collection || !collection.contract_addr || !collection.chain) {
    return <div className="no-contract">
      <h3>NFT contract is not deployed yet</h3>
      <Button
        className="deploy-button"
        type="submit"
        size="medium"
        color="primary"
        variant="contained"
        onClick={async () => {
          await handleDeploy();
        }}
      >
        Deploy
      </Button>
    </div>
  }

  const deployedChainId = chainIdNetworkConfigMap[collection.chain].chainId;
  if (!networkConfigs[chainId]) {
    return <div className="wrong-chain">
      You are connected to an unsupported chain
    </div>
  }
  if (deployedChainId !== chainId) {
    return <div className="wrong-chain">
      The deploy contract is on {networkConfigs[deployedChainId].name}, but you are connected to {networkConfigs[chainId].name}</div>
  }

  const contract = new web3.eth.Contract(abi, collection.contract_addr);

  return <div className="contract-form">
    <p>{collection.contract_addr}</p>
    {
      [...parsedAbi.calls, ...parsedAbi.methods].map(({ name: functionName, inputs, outputs, type, stateMutability, }, i) => {
        return <SingleForm
          contract={contract}
          key={`call-${functionName}-${i}`}
          stateMutability={stateMutability}
          inputs={inputs}
          account={account}
          functionName={functionName}
          onNotification={onNotification}
          updateCollection={updateCollection}
          collection={collection}
        />
      })
    }

  </div>
}