import * as React from 'react';
import {
    Layout as DefaultLayout,
    Menu as DefaultMenu,
    MenuItemLink,
    Sidebar as DefaultSidebar,
    AppBar as DefaultAppBar,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import {
    People as PeopleIcon,
    Collections as CollectionsIcon,
    CardGiftcard as CardGiftcardIcon,
    ShoppingBasket as ShoppingBasketIcon,
} from '@material-ui/icons';
import { useLocation, } from 'react-router-dom';
import styled from 'styled-components';
import { useParameter } from '../hooks/useParameter';
import { useUserInfo } from '../hooks/useUserInfo';
import { ConnectorButton } from '../common/ConnectorButton';

const StyleWrapper = styled.div`
    .multilayer-menu .multilayer-submenu {
        color: #999;
    }

    .multilayer-menu .active {
        color: #333;
    }

    .multilayer-menu .multilayer-submenu2 {
        margin-left: 24px;
    }

    .connector-button {
        position: absolute;
        bottom: 24px;
        left: 12px;
    }
`

export const Menu = (props) => {
    const location = useLocation();
    const { search } = useParameter();
    const isActive = (pathname) => {
        return location.pathname.includes(pathname);
    }
    return (
        <StyleWrapper>
        <DefaultMenu className="multilayer-menu" {...props}>
            <MenuItemLink
                to="/partner"
                className={`multilayer-submenu ${isActive('partner') ? 'active' : ''}`}
                primaryText="Partner"
                leftIcon={<PeopleIcon />}
            />
            <MenuItemLink
                to={`/collection?${search}`}
                className={`multilayer-submenu multilayer-submenu2 ${isActive('collection') ? 'active' : ''}`}
                primaryText="Collection"
                leftIcon={<CollectionsIcon />}
            />
            <MenuItemLink
                to={`/nft?${search}`}
                className={`multilayer-submenu multilayer-submenu2 ${isActive('nft') ? 'active' : ''}`}
                primaryText="NFT"
                leftIcon={<CardGiftcardIcon />}
            />
            <MenuItemLink
                to="/order"
                className={`multilayer-submenu ${isActive('order') ? 'active' : ''}`}
                primaryText="Order"
                leftIcon={<ShoppingBasketIcon />}
            />
        </DefaultMenu>
        </StyleWrapper>
    )
};


export const Sidebar = (props) => {
    return <DefaultSidebar {...props}>
        <Menu {...props} />
    </DefaultSidebar>
}

const useStyles = makeStyles(
    theme => ({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    }),
    { name: 'RaAppBar' }
);
export const AppBar = (props) => {
    const classes = useStyles(props);
    const { isAdmin } = useUserInfo();
    return <DefaultAppBar {...props}
    >
        <div className={classes.title}>MTL NFT Distribution Platform (NDP)</div>
        {isAdmin && <ConnectorButton />}
    </DefaultAppBar>
}


export const Layout = (props) =>
    <DefaultLayout
        {...props}
        sidebar={Sidebar}
        appBar={AppBar}
    >
        {props.children}
    </DefaultLayout>;