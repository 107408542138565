import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  useGetCollection,
  useUpdateCollection
} from '../api';

const CollectionContext = React.createContext({
  collection: null,
  getCollection: () => {},
  updateCollection: () => {},
  getCollectionLoading: true,
  updateCollectionLoading: false,
});

const CollectionContextProvider = ({
  children
}) => {
  const [collection, setCollection] = useState(null);

  const { sendRequest: sendGetCollectionRequest, loading: getCollectionLoading, data: collectionData,} = useGetCollection();
  const { sendRequest: sendUpdateCollectionRequest, loading: updateCollectionLoading, data: updatedCollectionData } = useUpdateCollection();

  const getCollection = useCallback(async (collectionId) => {
    sendGetCollectionRequest(collectionId);
  }, [sendGetCollectionRequest])

  const updateCollection = useCallback(async (collectionData) => {
    sendUpdateCollectionRequest(collectionData);
  }, [sendUpdateCollectionRequest]);

  useEffect(() => {
    if (collectionData) {
      setCollection(collectionData);
    }
  }, [collectionData]);

  useEffect(() => {
    if (updatedCollectionData) {
      setCollection(updatedCollectionData);
    }
  }, [updatedCollectionData]);

  return <CollectionContext.Provider value = {{ collection,
      getCollection,
      updateCollection,
      updateCollectionLoading,
      getCollectionLoading,
  }}>{children}</CollectionContext.Provider>;
};

export {
  CollectionContext,
  CollectionContextProvider
};