import React, { Fragment, useState, } from 'react';
import {
  useRefresh,
} from 'react-admin';
import { Popover, Button, ButtonGroup, Tooltip, Divider, Box } from '@material-ui/core';
import { Edit, ShoppingBasket, RemoveRedEye, HelpOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import { UploadButton, DownloadButton } from '../metadata/Uploader';
import { DistributionDetails } from './DistributionDetails';

import {
  normalTemplateAbi,
  sbtTemplateAbi,
} from '../common/NFTContractSettings';
import { ContractForm } from '../common/FastContract/ContractForm';
import { useNotification } from '../hooks/useNotification';
import { templateConfigMapById } from '../collection/config';

import "./index.css";

export const Operations = ({
  className,
  isAdmin,
  collectionId,
  partnerId,
  templateId,
  handleSelectReview,
  handleSelectApprove,
  handleMint,
  handleDeploy,
  filterIndex,
  distribution,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const [,sendNotification] = useNotification();


  const refresh = useRefresh();
  const confirm = useConfirm();

  return <div className="operations">
    <Button className="operation-button" aria-describedby="metadata" variant="contained" color="primary" onClick={(e) => {
      setAnchorEl(e.target);
      setOpen(true);
      setIndex(0);
    }}>
      Metadata
    </Button>
    <Button className="operation-button" aria-describedby="order" variant="contained" color="primary" onClick={(e) => {
      setAnchorEl(e.target);
      setOpen(true);
      setIndex(1);
    }}>
      Order
    </Button>
    <Button className="operation-button" aria-describedby="image" variant="contained" color="primary" onClick={(e) => {
      setAnchorEl(e.target);
      setOpen(true);
      setIndex(2);
    }}>
      Image
    </Button>
    <Button className="operation-button" aria-describedby="contract" variant="contained" color="primary" onClick={(e) => {
      confirm({
        title: <div>Contract <span style={{ fontSize: '16px' }}>({templateConfigMapById[templateId].name})</span></div>,
        content: <ContractForm
          abi={templateId === 1 ? normalTemplateAbi : sbtTemplateAbi}
          handleDeploy={handleDeploy}
          onNotification={(e) => {
            // trick!!!
            sendNotification(e);
          }}
        />,
        allowClose: true,
        dialogProps: {
          disableAutoFocus: true,
        },
        cancellationText: false,
      })
    }}>
      Contract
    </Button>
    <Popover
      disableAutoFocus
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setOpen(false);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="suboperation-wrapper">
        {
          index === 0 && <Fragment>
            <div className="suboperation-download-button-group">
              <UploadButton
                onSuccess={() => {
                  refresh();
                }}
                className="suboperation-left-button"
                size="small"
                color="primary"
                buttonText="Upload"
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                url={`/metadata/upload?collection_id=${collectionId}`}
              />
              <DownloadButton
                size="small"
                color="primary"
                buttonText="Download"
                className="suboperation-right-button"
                url={`/metadata/download?collection_id=${collectionId}`}
              />
            </div>
            <Button
              className="redirect-button"
              startIcon={<Edit />}
              color="primary"
              size="small"
              variant="outlined"
              component={Link}
              to={{
                pathname: `/metadata/${collectionId}`,
                search: '?step=0_1',
              }}
            >
              Metadata config
            </Button>
            <Button
              className="overview-button"
              startIcon={<RemoveRedEye />}
              color="primary"
              size="small"
              variant="outlined"
              onClick={async () => {
                await confirm({
                  title: 'NFT metadata overview',
                  description: <DistributionDetails 
                    distribution={distribution}
                  />,
                  allowClose: true,
                  dialogProps: {
                    disableAutoFocus: true,
                  },
                  cancellationText: false,
                });
              }}
            >
              Overview
            </Button>
          </Fragment>
        }
        {
          index === 1 && <Fragment>
            <div className="suboperation-download-button-group">
              <UploadButton
                onSuccess={() => {
                  refresh();
                }}
                className="suboperation-left-button"
                size="small"
                color="primary"
                buttonText="Upload"
                url={`/order/upload?collection_id=${collectionId}`}
              />
              <DownloadButton
                size="small"
                color="primary"
                className="suboperation-right-button"
                buttonText="Template"
                url={`/order/download_template`}
              />
            </div>
            <Button
              startIcon={<ShoppingBasket />}
              className="redirect-button"
              color="primary"
              size="small"
              variant="outlined"
              component={Link}
              to={{
                pathname: `/order`,
              }}
            >
              Order list
            </Button>
          </Fragment>
        }
        {
          index === 2 && <Fragment>
            <Box className="suboperation-download-button-group" sx={{
              flexDirection: 'column'
            }}>
              <UploadButton
                onSuccess={() => {
                  refresh();
                }}
                className="suboperation-left-button"
                size="small"
                color="primary"
                accept="application/zip"
                buttonText="Upload ZIP"
                endIcon={<Tooltip title="Batch upload multiple images, you can download example template below">
                  <HelpOutline />
                </Tooltip>}
                url={`/metadata/images/upload?collection_id=${collectionId}`}
              />
              <DownloadButton
                className="suboperation-right-button"
                size="small"
                color="primary"
                buttonText="template"
                url={`/metadata/images/template_download`}
              />
              <Divider />
              <UploadButton
                onSuccess={() => {
                  refresh();
                }}
                className="suboperation-left-button"
                size="small"
                color="primary"
                buttonText="Upload for all"
                accept="image/*"
                endIcon={<Tooltip title="Upload an image that will apply for all NFTs">
                  <HelpOutline />
                </Tooltip>}
                url={`/metadata/image/upload?collection_id=${collectionId}&mode=all`}
              />
            </Box>
          </Fragment>
        }
      </div>
    </Popover>

    <ButtonGroup
      color="primary"
      size="medium"
    >
      <Button
        variant={filterIndex === 0 ? 'outlined' : 'contained'}
        onClick={() => {
          handleSelectReview();
        }}
      >
        Request review
      </Button>
      {
        isAdmin && <Button
          variant={filterIndex === 1 ? 'outlined' : 'contained'}
          onClick={() => {
            handleSelectApprove();
          }}
        >
          Approve
        </Button>
      }
      {
        isAdmin && <Button
          variant={filterIndex === 2 ? 'outlined' : 'contained'}
          onClick={() => {
            handleMint();
          }}
        >
          Mint
        </Button>
      }
    </ButtonGroup>
  </div>
}