import React, { useState } from 'react';

const ParameterContext = React.createContext({ 
  partnerId: 0, collectionId: 0,
  setPartnerId: () => {},
  setCollectionId: () => {},
});

const ParameterContextProvider = ({ children }) => {
  const [partnerId, setPartnerId] = useState(undefined);
  const [collectionId, setCollectionId] = useState(undefined);


  return <ParameterContext.Provider value={{ 
    partnerId,
    collectionId,
    setPartnerId,
    setCollectionId 
  }}>{children}</ParameterContext.Provider>;
};

export { ParameterContext, ParameterContextProvider };
