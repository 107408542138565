import React, { useState, } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Inbox from '@material-ui/icons/Inbox';
import Search from '@material-ui/icons/Search';
import { stringify } from 'query-string';

import { Link, useLocation, } from 'react-router-dom';

import { useParameter } from '../hooks/useParameter';

export const Filter = (props) => {
    const classes = useStyles(props);
    const { allParams, } = useParameter();

    const [inputVal, setInputVal] = useState('');

    const location = useLocation();

    const emptyMessage = `No ${props.label} filter`

    const handleChange = (e) => {
        setInputVal(e.target.value);
    }

    return (
        <>
            <div className={classes.message}>
                <Inbox className={classes.icon} />
                <Typography variant="h4" paragraph>
                    {emptyMessage}
                </Typography>
            </div>
            <div className={classes.toolbar}>
                <Input
                    placeholder="search..."
                    onChange={handleChange}
                />
                <Button
                    disabled={!inputVal}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={{ 
                        pathname: `${location.pathname}`,
                        search: `?${stringify({
                            ...allParams,
                            [props.name]: inputVal,
                        })}`
                    }}
                    startIcon={<Search />}
                >
                    Confirm
                </Button>
            </div>
        </>
    );
};

const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'RaEmpty' }
);
