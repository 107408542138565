import React, { useState } from 'react';
import { Box, Tooltip, Popper as DefaultPopper, CircularProgress } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useRefresh } from 'react-admin';

import "./index.css";
import { LongText } from '../common/LongTextField';
import { UploadButton } from '../metadata/Uploader';

const Popper = (props) => {
    return <DefaultPopper {...props}>
        <img className="metadata-tooltip-image" src={props.src} alt="unavailable"/>
    </DefaultPopper>
}

export const MetadataBlock = ({ id, collection_id, mint_tx, nft_id, name, description, image_uri, traits: traitsString, distribution, }) => {
    const traits = JSON.parse(traitsString);

    const refresh = useRefresh();

    const [uploading, setUploading] = useState(false);

    if (!distribution) {
        return null;
    }

    return (
        <Box className="metadata-block">
            <div className="metadata-block-left">
                <div className="metadata-item">
                    <p className="name">Name:</p>
                    <p className="value">{name || '-'}</p>
                </div>
                <div className="metadata-item">
                    <p className="name">Description:</p>
                    <p className="value">
                        <LongText text={description || '-'} />
                    </p>
                </div>
            </div>
            <div className="metadata-block-middle">
                {image_uri
                    ? <Tooltip title="placeholder" sx={{
                        position: 'relative'
                    }} placement='left' PopperComponent={(props) => <Popper src={image_uri} {...props}/>}>
                        <Box>
                            { !mint_tx && 
                                <UploadButton
                                    accept="image/*"
                                    className="image-edit-button"
                                    onSuccess={() => {
                                        setUploading(false);
                                        refresh();
                                    }}
                                    onStart={() => setUploading(true)}
                                    onError={() => setUploading(false)}
                                    onAbort={() => setUploading(false)}
                                    url={`/metadata/image/upload?collection_id=${collection_id}&mode=single&nft_id=${nft_id}`}
                                >
                                    <Edit className="image-edit-button" color='action' />
                                </UploadButton>
                            }
                            {uploading ? <CircularProgress /> : <img src={image_uri} alt="nft-preview" />}
                        </Box>
                    </Tooltip>
                    : <div className="empty-image">NO IMAGE</div>}
            </div>
            <div className="metadata-block-right">
                {
                    traits.map(({trait_type, value}, i) => {
                        const { properties } = distribution;
                        return <div className="trait-item" key={`trait-${trait_type}-${i}`}>
                                <div className="type">{trait_type}</div>
                                <div className="value">{value}</div>
                                <div className="per">{
                                    properties[trait_type] && properties[trait_type][value]
                                        ? (properties[trait_type][value] / distribution.total * 100).toFixed(0)
                                        : 0
                                    }%</div>
                        </div>
                    })
                }
            </div>
        </Box>
    );
};