import * as React from 'react';
import { 
    Edit, 
    SimpleForm, 
    TextInput, 
    NumberInput, 
    Toolbar, 
    SaveButton, 
    SelectInput,
    required,
} from 'react-admin';
import { templateConfig } from './config';
// import { BackButton } from '../common/BackButton';


const CollectionEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const CollectionEdit = (props) => {
    return (<Edit undoable={false} {...props}>
        <SimpleForm toolbar={<CollectionEditToolbar />}>
            <NumberInput disabled label="ID" source="id" />
            <NumberInput disabled label="Partner ID" source="partner_id" />
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Description" source="description" validate={required()} />
            <TextInput label="Contract Token Symbol" source="symbol" validate={required()} />
            <TextInput label="Base URI" source="base_uri" />
            <NumberInput label="Total" source="total_supply" validate={required()} />
            <SelectInput label="Template" source="contract_template_id" choices={templateConfig} validate={required()} />
            {/* <SelectInput label="Royalty Reward" source="royalty_reward_rate" choices={[
                { id: 1, name: '1%' },
                { id: 2, name: '2%' },
                { id: 3, name: '3%' },
                { id: 4, name: '4%' },
                { id: 5, name: '5%' },
                { id: 6, name: '6%' },
                { id: 7, name: '7%' },
                { id: 8, name: '8%' },
                { id: 8, name: '9%' },
                { id: 8, name: '10%' },
            ]} validate={required()} /> */}
        </SimpleForm>
    </Edit>)
};