import { useState, useCallback, } from 'react';
import {
  fetchUtils,
} from 'react-admin';
import { apiUrl } from '../ApiProvider';
import { useNotification } from '../hooks/useNotification';
import { stringify } from 'query-string';

const httpClient = fetchUtils.fetchJson;

export const useQuery = ({
    needSuccessNotification = false,
    needErrorNotification = false,
    successMessage = '',
    errorMessage = '',
    initialLoading = false,
    onSuccess,
    onError,
}) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(initialLoading);
    const [data, setData] = useState(null);

    const [, sendNotification] = useNotification();

    const sendRequest = useCallback(({
        path,
        method = 'get',
        params = {},
    }) => {
        let url = `${apiUrl}${path}`;
        const requestOptions = {
            method,
            credentials: 'include',
        };
        if (method.toLowerCase() === 'post') {
            requestOptions.body = JSON.stringify(params);
        } else if (Object.keys(params).length !== 0) {
            url += `?${stringify(params)}`;
        }

        setLoading(true);

        return httpClient(url, requestOptions).then(({ json }) => {
            setLoading(false);
            if (json.code !== 0) {
                needErrorNotification && sendNotification({ 
                    msg: `Error ${errorMessage || json.code}: ${json.msg}`, 
                    variant: 'error', 
                });
                setData(null);
                setError(json.msg);
                onError && onError(json);
                throw new Error({ code: json.code, msg: json.msg});
            }
            const data = json.data;
            needSuccessNotification && sendNotification({ msg: successMessage || 'Success', variant: 'success' });
            setData(data);
            onSuccess && onSuccess(data);
            return data;
        });
    }, [
        setData, 
        setError, 
        onSuccess, 
        onError, 
        sendNotification, 
        needErrorNotification,
        needSuccessNotification,
        errorMessage, 
        successMessage,
    ]);


    return { error, loading, data, sendRequest, setError, setData };
}