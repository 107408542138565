export const parseAbi = (abi) => {
    return abi.reduce((target, abiItem, i) => {
        if (abiItem.stateMutability === 'view') {
            target.calls.push(abiItem);
        } else if (abiItem.type === 'event') {
            target.events.push(abiItem);
        } else if (abiItem.stateMutability === 'constructor') {
            target.constructors.push(abiItem);
        } else if (abiItem.type !== 'error') {
            target.methods.push(abiItem);
        }
        return target;
    }, {
        calls: [],
        methods: [],
        events: [],
        constructors: [],
    });
}