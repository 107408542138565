// data transformers
// ======= config
export const metadataConfigRemote2LocalTransformer = ({
    id,
    collection_id,
    traits_config,
    combo_type
}) => {
    const traits_config2 = traits_config.map(({
        trait_types,
        trait_values,
        quantity
    }) => {
        return {
            traits: trait_types.map((type, index) => {
                return {
                    type,
                    value: trait_values[index]
                }
            }),
            quantity,
        }
    })
    return {
        id,
        collection_id,
        combo_type,
        traits_config: traits_config2
    };
};

export const metadataConfigLocal2RemoteTransformer = ({
    id,
    collection_id,
    traits_config,
    combo_type
}) => {
    const isRandom = combo_type === 'random';
    const traits_config2 = traits_config.map(({
        traits,
        quantity
    }) => {
        return {
            trait_types: (isRandom ? traits.slice(0, 1) : traits).map(({
                type,
                value
            }) => type),
            trait_values: (isRandom ? traits.slice(0, 1) : traits).map(({
                type,
                value
            }) => value),
            quantity: Number(quantity),
        }
    });
    return {
        collection_id,
        combo_type,
        traits_config: traits_config2
    };
}

// === traits
const deduplicateKeyValuePairs = (trait_key_value_pairs) => {
    const salt = Date.now();
    const keyMap = {};
    const result = [];
    trait_key_value_pairs.forEach(({trait_key, trait_value}) => {
      const key = `${trait_key}${salt}${trait_value}`;
      if (!keyMap[key]) {
        keyMap[key] = true;
        result.push({trait_key, trait_value});
      }
    })
    return result;
  }

export const metadataTraitsRemote2LocalTransformer = (items) => {
    const traitValueMap = {};
    items.forEach(({
        collection_id,
        trait_key,
        trait_value,
    }) => {
        if (!traitValueMap[trait_key]) {
            traitValueMap[trait_key] = [];
        }
        if (traitValueMap[trait_key].includes(trait_value)) {
            return;
        }
        traitValueMap[trait_key].push(trait_value);
    })
    const items2 = Object.keys(traitValueMap).map((type) => {
        return {
            trait_key: type,
            trait_values: traitValueMap[type],
        }
    })
    return items2;
};

export const metadataTraitsLocal2RemoteTransformer = (items) => {
    const items2 = items.reduce((target, { trait_key, trait_values,}) => {
        if (!trait_key) {
            return target;
        }
        if (!trait_values || !trait_values.length) {
            return target;
        }
        return [...target, ...trait_values.map(trait_value => ({ trait_key, trait_value }))]
    }, []);
    return deduplicateKeyValuePairs(items2);
};


// distribution
export const distributionRemote2LocalTransformer = (item) => {
    let total = 0;
    Object.keys(item).forEach(type => {
        const values = item[type];
        Object.keys(values).forEach(value => {
            total += values[value];
        });
    });
    return {
        properties: item,
        total,
    }
}

