import {
    fetchUtils
} from 'react-admin';
import { stringify } from 'query-string';


const httpClientRaw = fetchUtils.fetchJson;

const httpClient = (url, options = {}) => {
    return httpClientRaw(url, {
        ...options,
        credentials: 'include',
    })
}

export const EMPTY_RESOURCE = 'EMPTY_RESOURCE';

export const dataAdaptor = (json) => {
    if (json.code === 0) {
        return json.data;
    }
    // eslint-disable-next-line no-throw-literal
    throw { message: json.msg, code: json.code };
}

export const apiUrl = process.env.NODE_ENV === 'development' ? '' : (process.env.API_URL || '/api');

const transformers = {
    nft(items) {
        return items.map(({ metadata, order, ...rest }) => {
            return {
                ...rest,
                metadata,
                order,
            }
        })
    }
}

const apiProvider = {
    async getList(resource, params) {
        if (resource === EMPTY_RESOURCE) {
            console.log('[getList] empty!!!')
            return Promise.resolve({
                data: [], total: 0,
            });
        }
        const {
            page,
            perPage
        } = params.pagination;

        let queries = {}

        if (page) {
            queries.page_num = page;
        }

        if (perPage) {
            queries.page_size = perPage;
        }

        let url = `${apiUrl}/${resource}/list`;

        const otherFilter = params.filter;
        if (otherFilter && Object.keys(otherFilter).length) {
            queries = {
                ...queries,
                ...otherFilter,
            }
        }

        if (Object.keys(queries).length) {
            url += `?${stringify(queries)}`;
        }

        console.log(`[getList] - ${url}`, params, resource);

        return httpClient(url).then(({ headers, json }) => {
            let data = dataAdaptor(json);

            if (data.items && transformers[resource]) {
                data.items = transformers[resource](data.items);
            }
            return {
                data: data.items || [],
                total: data.total_count,
            }
        });
    },

    async getOne(resource, params) {
        const url = `${apiUrl}/${resource}/${params.id}`;
        console.log(`[getOne] - ${url}`, params);
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            return { data: dataAdaptor(json).item };
        });
    },

    async getMany(resource, params) {
        return Promise.resolve();
        // const query = {
        //     filter: JSON.stringify({
        //         ids: params.ids
        //     }),
        // };
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;
        // return httpClient(url).then(({
        //     json
        // }) => ({
        //     data: json
        // }));
    },

    async getManyReference(resource, params) {
        return Promise.resolve();
    },

    async update(resource, params) {
        const url = `${apiUrl}/${resource}/${params.id}/update`;
        console.log(`[update] - ${url}`, params);
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            dataAdaptor(json); // check
            return {
                data: params.data,
            };
        });
    },

    async updateMany(resource, params) {
        return Promise.resolve();
        // const query = {
        //     filter: JSON.stringify({
        //         id: params.ids
        //     }),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({
        //     json
        // }) => ({
        //     data: json
        // }));
    },

    async create(resource, params) {
        const url = `${apiUrl}/${resource}/create`;
        console.log(`[create] - ${url}`, params);
        return httpClient(`${apiUrl}/${resource}/create`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({
            json
        }) => {
            return {
                data: dataAdaptor(json).item,
            };
        });
    },

    async delete(resource, params) {
        return Promise.resolve();
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({
        //     json
        // }) => ({
        //     data: json
        // }));
    },
    async deleteMany(resource, params) {
        return Promise.resolve();
        // const query = {
        //     filter: JSON.stringify({
        //         id: params.ids
        //     }),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'DELETE',
        //     body: JSON.stringify(params.data),
        // }).then(({
        //     json
        // }) => ({
        //     data: json
        // }));
    },
    async downloadFile() {

    },
    async uploadFile() {

    },
}

export default apiProvider;