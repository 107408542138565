const matchers = [
    ['invalid address', 'Invalid address'],
]

export const friendlifyMessage = (msg) => {
    for (let i = 0; i < matchers.length; i++) {
        if (msg.toLowerCase().includes(matchers[i][0])) {
            return matchers[i][1];
        }
    }
    return msg;
}
