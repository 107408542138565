import {
  useCallback,
} from 'react';
import {
  useWeb3React
} from '@web3-react/core'
import {
  normalTemplateAbi,
  sbtTemplateAbi,
  normalTemplateCode,
  sbtTemplateCode,
} from '../common/NFTContractSettings';
import {
  useWeb3
} from './useWeb3';
import {
  useNotification
} from './useNotification';


export const useCreateNftContract = () => {
  const {
    account
  } = useWeb3React();
  const web3 = useWeb3();
  const [, sendNotification] = useNotification();

  const deploy = useCallback(({
    baseUri,
    totalSuppy,
    name,
    symbol,
    templateId,
  }) => {
    if (account && web3) {
      if (!name) {
        const msg = 'name or symbol empty, please correct your collection info';
        sendNotification({ msg, variant: 'error', });
        throw new Error(msg);
      }

      let createTx = '';
      let contract = new web3.eth.Contract(templateId === 1 ? normalTemplateAbi : sbtTemplateAbi);

      return contract.deploy({
          data: '0x' + (templateId === 1 ? normalTemplateCode : sbtTemplateCode),
          arguments: [baseUri || '', name || '', symbol || '', totalSuppy],
        })
        .send({
          from: account,
        }, (error, tx) => {
          createTx = tx;
        })
        .then(newContractInstance => {
          const newAddress = newContractInstance.options.address;
          sendNotification({
            msg: 'Deploy success, contract address: ' + newAddress,
            variant: 'success',
          });
          return { address: newAddress, createTx, };
        }).catch(e => {
          const msg = 'Deploy failed' + e.message;
          sendNotification({
            msg,
            variant: 'error',
          });
          throw new Error(msg)
        })
    }
  }, [account, web3, sendNotification]);
  return { deploy };
}
