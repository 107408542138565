import * as React from 'react';
import { List, 
    FunctionField, 
    ExportButton, 
    TopToolbar, 
    Datagrid, 
    TextField,
    TextInput,
} from 'react-admin';
import { Empty } from '../common/Empty';
import { AddressLink } from '../common/EthscanLink';
import { Pagination } from '../common/Pagnation';
import { Link } from 'react-router-dom';


const ListActions = (props) => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
);

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

export const OrderList = (props) => {
    return (<List {...props} 
            filters={postFilters}
            empty={<Empty />}
            syncWithLocation={false}
            actions={<ListActions/>}
            bulkActionButtons={false}
            pagination={<Pagination />}
        >
        <Datagrid>
            <TextField sortable={false} label="ID" source="id" />
            <TextField sortable={false} label="Username" source="user_name" />
            <TextField sortable={false} label="Email" source="email" />
            <FunctionField sortable={false}
                label="Collection"
                render={record => <Link to={`/collection/${record.collection_id}`}>{record.collection && record.collection.name}</Link>}
            />

            <FunctionField sortable={false}
                label="Channel / OrderID"
                render={record => <div><span>{record.order_channel}</span> / {record.external_order_id}</div>}
            />

            <TextField sortable={false} label="Status" source="status" />
            <AddressLink source="user_wallet_addr" title="User Wallet Address" />

            <FunctionField sortable={false}
                label="Quantity / Minted"
                render={record => <div>{record.purchase_amount} / {record.minted_amount}</div>}
            />

            <TextField sortable={false} label="Reserved Token IDs" source="reserved_token_ids" />
            <TextField sortable={false} label="Order Time" source="create_time" />

        </Datagrid>
    </List>)
};