import * as React from 'react';
import { List, 
    NumberField, 
    EditButton, 
    CreateButton, 
    ExportButton, 
    TopToolbar, 
    Datagrid, 
    TextField,
    TextInput,
    FunctionField,
} from 'react-admin';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useParameter } from '../hooks/useParameter';
import { Empty } from '../common/Empty';
import { Filter } from '../common/Filter';
import { LongText } from '../common/LongTextField';
import { TxLinkRaw, AddressLinkRaw, } from '../common/EthscanLink';
import { Pagination } from '../common/Pagnation';
import { templateConfig } from './config';

const StyleWrapper = styled.div`
    min-width: 120px;

    .empty-text {
        color: #999;
    }

    .multiline-field-primary {
        margin: 0;
        font-weight: 700;
    }

    .multiline-field-secondary {
        margin: 0;
    }
`;


const ListActions = (props) => (
    <TopToolbar>
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const NftButton = ({ record, basePath, resource, ...rest }) => {
    return (
        <Button
            color="primary"
            component={Link}
            to={{ 
                pathname: `/nft`,
                search: `?collection_id=${record.id}`
            }}
        >
            NFTs
        </Button>
    )
}

const EditMetadataButton = ({ record, basePath, resource, partnerId }) => {
    return (
        <Button
            startIcon={<Edit />}
            color="primary"
            component={Link}
            to={{ 
                pathname: `/metadata/${record.id}`,
                search: '?step=0_1',
            }}
        >
            Metadata config
        </Button>
    )
}



const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

export const CollectionList = (props) => {
    const { partnerId } = useParameter();

    if (!partnerId) {
        return <Filter name="partner_id" label="Partner ID" />;
    }

    return (<List 
        {...props} 
        syncWithLocation={false} 
        actions={<ListActions partnerId={partnerId} />}
        bulkActionButtons={false}
        filterDefaultValues={{ partner_id: partnerId }}
        filters={postFilters}
        empty={<Empty />}
        pagination={<Pagination />}
    >
        <Datagrid>
            <NumberField sortable={false} label="ID" source="id" />
            <NumberField sortable={false} label="Partner" source="partner.name" />

            <FunctionField 
                sortable={false}
                label="Name / Symbol"
                render={record => {
                    return <StyleWrapper>
                        <p className="multiline-field-primary">{record.name}</p>
                        <p className="multiline-field-secondary">{record.symbol}</p>
                    </StyleWrapper>
                }}
            />

            <FunctionField 
                sortable={false}
                label="Base URI / Description"
                render={record => {
                    return <StyleWrapper>
                        <p className="multiline-field-primary">{
                            record.base_uri || <span className="empty-text">None</span>}
                        </p>
                        <p className="multiline-field-secondary">{
                            <LongText text={record.description} /> || <span className="empty-text">None</span>
                        }</p>
                    </StyleWrapper>
                }}
            />

            <NumberField sortable={false} label="Total" source="total_supply" />
            <FunctionField 
                sortable={false}
                label="Contract Template"
                render={record => {
                    const found = templateConfig.find(config => 
                        Number(config.id) === Number(record.contract_template_id));
                    return <p>{found ? found.name : null}</p>
                }}
            />
            <TextField sortable={false} label="Chain" source="chain" />

            <FunctionField 
                label="Creation Transaction / Contract Address"
                render={record => {
                    return <StyleWrapper>
                        <div className="multiline-field">
                            <TxLinkRaw record={record} source="create_tx" />
                        </div>
                        <div className="multiline-field">
                            <AddressLinkRaw record={record} source="contract_addr" />
                        </div>
                    </StyleWrapper>
                }}
            />

            <EditButton />
            <EditMetadataButton />
            <NftButton partnerId={partnerId} />
        </Datagrid>
    </List>)
};