import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import AddIcon from '@material-ui/icons/Add';
import { Loading } from 'react-admin';

import { EditFooter } from './EditFooter';
import { MuiField, MuiChips, } from './FinalForms';
import { useUpdateMetadataTraits, useGetMetadataTraits, } from '../api';

import './index.css';

export const MetadataTraitsConfig = ({
  id: collectionId,
  activeStep,
  onBack,
  onNext,
  backText,
  nextText,
}) => {
  const [loading, setloading] = useState(true);
  const updateMetadataTraitsQuery = useUpdateMetadataTraits();
  const getMetadataTraitsQuery = useGetMetadataTraits();

  useEffect(() => {
    setloading(getMetadataTraitsQuery.loading || updateMetadataTraitsQuery.loading);
  }, [getMetadataTraitsQuery.loading, updateMetadataTraitsQuery])

  const { sendRequest: sendGetMetadataTraitsRequest } = getMetadataTraitsQuery;
  useEffect(() => {
    sendGetMetadataTraitsRequest(collectionId);
  }, [collectionId, sendGetMetadataTraitsRequest])

  let finalValues = {
    items: getMetadataTraitsQuery.data,
    collection_id: collectionId,
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
    >
      <Form
        onSubmit={() => {}}
        initialValues={finalValues}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators
        }}
        // validate={validate}
        render={({ handleSubmit, pristine, invalid, values }) => {
          finalValues = values;
          return <form onSubmit={handleSubmit} className="metadata-traits-edit-form">
            <MuiField type="number" className="metadata-trait-edit-form-item" name="collection_id" label="Collection ID" disabled />
            <div className="label-small">Trait key & value pairs (Duplicated values will get pruned)</div>
            <FieldArray className="metadata-trait-edit-form-item" name="items">
              {({ fields }) => (
                <div>
                  {fields.map((name, index) => (
                    <div key={name} className="metadata-trait-edit-row">
                      <span className="metadata-config-edit-row-index">{index}</span>
                      <div className="metadata-trait-edit-inner">
                        <MuiField className="metadata-trait-edit-key" name={`${name}.trait_key`} label="Trait Key" />
                        <MuiChips className="metadata-trait-edit-values" name={`${name}.trait_values`} label="Trait Value" />
                      </div>
                      <Button
                        color="primary"
                        startIcon={<RemoveIcon />}
                        className="metadata-trait-remove-button" onClick={() => fields.remove(index)}>
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    color="primary"
                    className="metadata-trait-add-button"
                    startIcon={<AddIcon />}
                    type="button"
                    onClick={() => fields.push({ trait_key: '', trait_values: [] })}
                  >
                    Add
                  </Button>
                </div>
              )}
            </FieldArray>
          </form>
        }}
      />
      
      <EditFooter>
      <Button
          color="primary"
          variant="contained"
          onClick={onBack}
          sx={{ mr: 1 }}
        >
          {backText}
        </Button>
        <Button 
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={async () => {
            await updateMetadataTraitsQuery.sendRequest(finalValues);
            onNext();
          }}>
          {nextText}
        </Button>
        <Button 
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={onNext}>
          Skip
        </Button>
      </EditFooter>
    </Box>
  )
};