import React from 'react';
import { Chip, } from '@material-ui/core';

export const ConfirmDescription = ({
  selectedData,
  subText,
}) => {
  const recipients = Object.keys(selectedData).reduce((target, id) => {
    const { recipient_address: recipient, token_id, } = selectedData[id];
    if (!target[recipient]) {
      target[recipient] = [];
    }
    target[recipient].push(token_id);
    return target;
  }, {})
  return <div > {
    Object.keys(recipients).map((recipient, index) => {
      return <div className="confirm-desc-row" key={
        `${recipient}-${index}`
      }>
        <div className="confirm-desc-title">
          <span className="confirm-desc-title-address">{recipient}</span>
          {subText && <span>{subText}</span>}
        </div>
        <div className="confirm-desc-ids">
          {
            recipients[recipient].map(tokenId => {
              return (
                <Chip 
                  key={`token-${tokenId}`}
                  className="confirm-desc-id-chip"
                  label={tokenId}
                  size="small"
                  color="primary"
                  variant="outlined" />
              );
            })
          }
        </div>
      </div>
    })
  } </div>
};