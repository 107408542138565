import React, {Fragment, useEffect, useState} from "react";
import { useSnackbar } from 'notistack';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const NORMAL_DURATION = 3000;
const ERROR_DURATION = 3000;

export const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    useEffect(() => {
        const action = key => (
            <Fragment>
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            </Fragment>
        );
        if(conf?.msg){
            let variant = 'info';
            if(conf.variant){
                variant = conf.variant;
            }
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: conf.variant === 'error' ? ERROR_DURATION : NORMAL_DURATION,
                anchorOrigin: {
                    vertical: 'bottom', horizontal: 'center'
                },
                onClose: closeSnackbar,
                action
            });
        }
    }, [conf, closeSnackbar, enqueueSnackbar]);
    return [conf, setConf];
};
