// in src/App.js
import * as React from "react";
import { Admin, Resource, } from 'react-admin';
import { Web3ReactProvider } from '@web3-react/core';
import { ConfirmProvider } from 'material-ui-confirm';
import { Layout } from './layout';
import { Login } from './login';
import { PartnerList, PartnerCreate, PartnerEdit, PartnerShow } from './partner';
import { CollectionList, CollectionCreate, CollectionEdit, MetadataConfig } from './collection';
import { NftsList } from './nfts';
import { OrderList, OrderShow } from './order';
import ApiProvider from './ApiProvider';
import authProvider from './authProvider';
import { SnackbarProvider } from 'notistack';
import { ParameterContextProvider } from "./context/ParameterContext";
import { Web3ReactManager } from "./context/Web3ReactManager";
import { CollectionContextProvider } from "./context/CollectionContext";


const dataProvider = ApiProvider;

const App = () =>
  <Web3ReactProvider getLibrary={(provider) => provider}>
    <SnackbarProvider maxSnack={3}>
      <Web3ReactManager>
        <ParameterContextProvider>
          <CollectionContextProvider>
            <ConfirmProvider>
              <Admin layout={Layout} loginPage={Login} authProvider={authProvider} dataProvider={dataProvider}>
                <Resource name="partner" list={PartnerList} edit={PartnerEdit} create={PartnerCreate} show={PartnerShow} />
                <Resource name="collection" list={CollectionList} edit={CollectionEdit} create={CollectionCreate} />
                <Resource name="nft" options={{ label: 'NFTs' }} list={NftsList} />
                <Resource name="order" list={OrderList} show={OrderShow} />
                <Resource name="metadata" edit={MetadataConfig} />
              </Admin>
            </ConfirmProvider>
          </CollectionContextProvider>
        </ParameterContextProvider>
      </Web3ReactManager>
    </SnackbarProvider>
  </Web3ReactProvider>
export default App;