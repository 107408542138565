import React from 'react';
import { MuiField, } from './FinalForms';
import { Field } from 'react-final-form';

import './index.css';


export const RandomInputArray = ({ name, traitDictionary, traitIndexMap, }) => {
  // name should be traits_config, this component will modify this whole part
  
  return (
    <div className="random-input-array">
      <Field name={name}>
        {(props) => {
          const rows = Object.keys(traitDictionary).map((type, index) => {
            const values = traitDictionary[type];
            return <div key={`${type}-${index}`}>
              <div className="metadata-config-edit-random">
                <div className="metadata-config-edit-random-title">{type}</div>
                <div className="metadata-config-edit-random-row">
                {
                  values.map(value => {
                    const index = traitIndexMap[type][value];
                    return <MuiField
                      key={`${value}-${type}`}
                      type="number"
                      name={`${name}[${index}].quantity`}
                      label={`${value} count`}
                    />
                  })
                }
                </div>
              </div>
            </div>
          })
          return rows;
        }}

      </Field>
    </div>
  )
}
