import { useCallback, } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';

import { useNotification } from './useNotification';
import { setupNetwork, injectedConnector, } from '../common/network';

const useAuth = () => {
  const [,sendNotification] = useNotification();
  const { activate, deactivate } = useWeb3React();
  const login = useCallback(
    (newChainId) => {
      console.log(`activating...`, newChainId, injectedConnector)
      activate(injectedConnector, async error => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork(newChainId);
          if (hasSetup) {
            activate(injectedConnector);
          }
        } else {
          if (error instanceof NoEthereumProviderError) {
            sendNotification({ msg: 'No provider was found', variant: 'error' });
          } else if (error instanceof UserRejectedRequestErrorInjected) {
            sendNotification({ msg: 'Please authorize to access your account', variant: 'error' });
          } else {
            console.error({ msg: error.message, variant: 'error' });
          }
        }
      });
    },
    [activate, sendNotification],
  );

  const logout = useCallback(() => {
    deactivate();
  }, [deactivate]);

  return { login, logout };
};

export default useAuth;
