import React from 'react';
import {
  Box, Chip, Avatar,
} from '@material-ui/core';


// "Head": {
//   "Buddha": 500,
//   "Dumplings": 500,
//   "Rice": 500
// },
// "Type": {
//   "Ghost": 1000,
//   "Human": 250,
//   "Zombie": 250
// }
export const DistributionDetails = ({
  distribution,
}) => {
  const { properties } = distribution;
  return <Box className="distribution-container">
    <div>
      {
        Object.keys(properties).map((type, i) => {
          const valueCountMap = properties[type];
          const values = Object.keys(valueCountMap);
          const totalOfThisType = values.reduce((target, value) => {
            return target + valueCountMap[value];
          }, 0)
          return (
            <div className="distribution-type-item" key={`key-${type}-${i}`}>
              <h4>{type}</h4>
              <div className="distribution-type-total">{totalOfThisType}</div>
              <div className="distribution-value-row">
                {
                  Object.keys(valueCountMap).map((value, i) => {
                    return (
                      <div key={`value-${value}-${i}`} className="distribution-value-item">
                        <Chip color="primary"
                          variant="outlined"
                          avatar={<Avatar className="distribution-value-chip-avatar">{value}</Avatar>}
                          label={
                            <div>
                              <span className="distribution-value-value">{valueCountMap[value]}</span>
                              <span className="distribution-value-per">{(valueCountMap[value] / totalOfThisType * 100).toFixed(2)}%</span>
                            </div>
                          } />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  </Box>
};