import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import SettingsApplications from '@material-ui/icons/SettingsApplications';


import './index.css';

export const PreSelection = ({ id, handleSelect }) => {
    return (
        <Box className="pre-selection-container">
            <h2>SELECT A WAY OF NFT GENERATION</h2>
            <Button
                startIcon={<CloudUpload />}
                className="pre-selection-button"
                variant="contained"
                color="primary"
                onClick={() => {
                    handleSelect(1);
                }}
            >
                Upload a metadata file
            </Button>
            <Button
                startIcon={<SettingsApplications />}
                className="pre-selection-button"
                variant="contained"
                color="primary"
                onClick={() => {
                    handleSelect(2);
                }}
            >
                Metadata generation
            </Button>
        </Box>
    )
};