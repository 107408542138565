export const templateConfig = [
    { id: 1, name: 'Normal batch minter' },
    { id: 2, name: 'Soulbound Token' },
];

export const templateConfigMapById = templateConfig.reduce((target, item) => {
    return {
        ...target,
        [item.id]: item,
    }
}, {});
