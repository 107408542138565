import {
  useEffect,
  useState,
} from 'react';
import Web3 from 'web3';
import {
  useWeb3React
} from '@web3-react/core';
import { networkConfigs, } from '../common/network';


const getWeb3NoAccount = (chainId) => {
  if (!chainId) {
    return null;
  }
  const httpProvider = new Web3.providers.HttpProvider(networkConfigs[chainId].rpcUrl, {
    timeout: 10000,
  });
  const web3NoAccount = new Web3(httpProvider);
  return web3NoAccount;
};

export { getWeb3NoAccount, Web3 }

export const useWeb3 = () => {
  const {
    library,
    chainId,
  } = useWeb3React();
  const [web3, setWeb3] = useState(library ? new Web3(library) : getWeb3NoAccount(chainId));

  useEffect(() => {
    setWeb3(library ? new Web3(library) : getWeb3NoAccount());
  }, [library]);

  return web3;
};