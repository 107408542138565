import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import styled from 'styled-components';

import {
    fetchUtils,
    useRedirect,
} from 'react-admin';
import { apiUrl } from '../ApiProvider';
import { useNotification } from '../hooks/useNotification';

const LoginWrapper = styled.div`
.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
}


.login-box .login-password-email {
    margin-bottom: 24px;
}

.login-box .login-icon {
    align-self: center;
    font-size: 64px;
    margin-bottom: 24px;
}

.login-box .login-password-input {
    margin-top: 24px;
}

.login-box .submit-button {
    margin-top: 8px;
}

.login-box .secondary-button {
    margin-top: 8px;
}

.change-password {
    cursor: pointer;
    font-size: 12px;
}
`;


const httpClient = fetchUtils.fetchJson

const afterLogin = (username, roleId, roleName) => {
    console.log(`logined, username: ${username}, roleId: ${roleId}, roleName: ${roleName}`)
    localStorage.setItem('username', username);
    localStorage.setItem('roleId', roleId);
    localStorage.setItem('roleName', roleName);

}

const LOGIN_MODE = 0;
const REGISTER_MODE = 1;
const RESET_PWD_MODE = 2;


// match(passwordRegex)
export const Login = () => {
    const [mode, setMode] = useState(LOGIN_MODE);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');


    const [, sendNotification] = useNotification();

    const redirect = useRedirect();

    return (<LoginWrapper>
        <form
            className="login-box"
            component="form"
            onSubmit={async (e) => {
                e.preventDefault();
                let response;

                switch (mode) {
                    case REGISTER_MODE: {
                        response = await httpClient(`${apiUrl}/auth/register`, {
                            method: 'POST',
                            credentials: 'include',
                            body: JSON.stringify({
                                email,
                                username: name,
                                password,
                            })
                        });
                        break;
                    }
                    case LOGIN_MODE: {
                        response = await httpClient(`${apiUrl}/auth/login`, {
                            method: 'POST',
                            credentials: 'include',
                            body: JSON.stringify({
                                username: name,
                                password,
                            })
                        });
                        break;
                    }
                    case RESET_PWD_MODE: {
                        response = await httpClient(`${apiUrl}/auth/modify_pwd`, {
                            method: 'POST',
                            credentials: 'include',
                            body: JSON.stringify({
                                username: name,
                                new_password: newPassword,
                                old_password: oldPassword,
                            })
                        });
                        break;;
                    }
                    default:
                        break;
                }

                const json = response.json;
                if (json.code === 0) {
                    sendNotification({ msg: 'Success', variant: 'success' });
                } else {
                    sendNotification({ msg: 'Error:' + json.msg, variant: 'error' });
                    return;
                }

                if (mode === REGISTER_MODE || mode === RESET_PWD_MODE) {
                    // go to login page
                    setMode(LOGIN_MODE);
                } else {
                    // login success
                    const item = json.data.item;
                    console.log('auth item', item);
                    afterLogin(item.account.username, item.role.id, item.role.name);
                    redirect('/partner');
                }
            }}
        >
            <AccountCircleIcon className="login-icon" color="primary" sx={{ fontSize: 64 }} />
            {
                mode === REGISTER_MODE && <TextField
                    className="login-password-email"
                    required
                    id="outlined-required"
                    label="Email"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                />
            }
            <TextField
                required
                id="outlined-required"
                label="Username"
                name="username"
                onChange={e => setName(e.target.value)}
            />
            {(mode === LOGIN_MODE || mode === REGISTER_MODE) &&
                <TextField
                    className="login-password-input"
                    required
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    helperText="Password must contain at least 8 charaters"
                />
            }
            {
                mode === RESET_PWD_MODE && <><TextField
                    className="login-password-input"
                    required
                    id="outlined-password-input"
                    label="old password"
                    type="password"
                    name="old_password"
                    onChange={e => setOldPassword(e.target.value)}
                />
                    <TextField
                        className="login-password-input"
                        required
                        id="outlined-password-input"
                        label="new password"
                        type="password"
                        name="new_password"
                        onChange={e => setNewPassword(e.target.value)}
                        helperText="Password must contain at least 8 charaters"
                    /></>
            }
            <Box sx={{ mt: 2, mb: 2 }}  />
            {
                mode !== RESET_PWD_MODE && <Typography className="change-password" color="primary" onClick={() => {
                    setMode(RESET_PWD_MODE);
                }}>
                    Change your password
                </Typography>
            }
            <Button className="submit-button" variant="contained" color="primary" type="submit">{
                ['Login', 'Register', 'Submit'][mode]
            }</Button>
            {
                (mode === LOGIN_MODE || mode === RESET_PWD_MODE) && <Button className="secondary-button" color="primary" onClick={() => {
                    setMode(REGISTER_MODE);
                }}>Register</Button>
            }
            {
                (mode === REGISTER_MODE || mode === RESET_PWD_MODE) && <Button className="secondary-button" color="primary" onClick={() => {
                    setMode(LOGIN_MODE);
                }}>Login</Button>
            }
        </form>
    </LoginWrapper>);
}
