import { useContext } from 'react';
import { CollectionContext } from '../context/CollectionContext';

export const useCollection = () => {
  const {
    collection,
    getCollection,
    updateCollection,
    updateCollectionLoading,
    getCollectionLoading,
  } = useContext(CollectionContext);
  return {
    collection,
    getCollection,
    updateCollection,
    updateCollectionLoading,
    getCollectionLoading,
  };
};

