import React, { useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { MuiField } from '../../metadata/FinalForms';
import { Button, } from '@material-ui/core';
import styled from 'styled-components';


const StyleWrapper = styled.div`
  .abi-item {
    border: 1px solid #ccc;
    border-left: 4px solid #c97539;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 8px;
}

.abi-item.abi-item-method {
    border-left: 4px solid #3f51b5;
}

.abi-item h5 {
    margin: 0;
    margin-bottom: 8px;
}

.send-button {
    margin-top: 8px;
}

.abi-item-inputs {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.abi-item-input {
    margin-right: 8px;
    margin-bottom: 8px;
}

.abi-item-result {
    color: #666;
    margin-left: 8px;
    font-size: 14px;
}
`


export const SingleForm = ({
    contract,
    stateMutability,
    inputs,
    account,
    functionName,
    onNotification,
    collection,
    updateCollection,
  }) => {
  
    const [txResult, setTxResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const isView = stateMutability === 'view';
    const isMethod = stateMutability === 'nonpayable';
    return <Form
      onSubmit={async (e) => {
        try {
          const parameters = inputs.map(({ name, type }, i) => {
            if (type.includes('[]')) {
              return e[name].split(',');
            }
            return e[name];
          });
          let returns = [];
          if (!account) {
            onNotification({ msg: 'Please connect your wallet', variant: 'error', });
            return;
          }
          setLoading(true);
  
          if (isView) {
            console.log(`read - functionName`, functionName, 'parameters', parameters);
            returns = await contract.methods[functionName](...parameters).call();
            setTxResult(returns);
          } else {
            console.log(`write - parameters`, parameters);
            returns = await contract.methods[functionName](...parameters).send({
              from: account,
            });
            if (functionName === 'setBaseURI') {
              await updateCollection({
                ...collection,
                base_uri: parameters[0],
              });
            }
            setTxResult(returns.transactionHash);
          }
          //
        }
        catch (e) {
          onNotification({ msg: 'Error when calling function: ' + e.message, variant: 'error', });
        }
        setLoading(false);
      }}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators
      }}
      render={({ handleSubmit, pristine, invalid, values }) => {
        return <StyleWrapper><form className={`abi-item ${isView ? 'abi-item-view' : 'abi-item-method'}`}
          onSubmit={handleSubmit}
        >
          <h5>{functionName}</h5>
          <div className="abi-item-inputs">
            {
              inputs.map(({ name: inputName, type: inputType }, j) => {
                return <MuiField
                  key={`input-${inputName}-${j}`}
                  className="abi-item-input"
                  name={`${inputName}`} // source
                  label={inputName}
                  variant="outlined"
                  size="small"
                  placeholder={inputType}
                  required={inputType !== 'bytes'}
                />;
              })
            }
          </div>
          <div className="abi-item-footer">
            <Button
              className="send-button"
              type="submit"
              size="small"
              color={isView ? "primary" : 'secondary'}
              variant="contained"
            >
              {isView && 'Read'}
              {isMethod && 'Write'}
            </Button>
            <span className="abi-item-result">{
              loading === true ? 'Loading...' : (
                txResult && `Result: ${JSON.stringify(txResult)}`
              )
            }</span>
          </div>
        </form></StyleWrapper>
      }}
    />
  
  }
  