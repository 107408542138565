import {
    fetchUtils
} from 'react-admin';
import { 
    apiUrl,
} from './ApiProvider';

const UNAUTH_ERROR_CODE = 5001;

const logout = async () => {
    return fetchUtils.fetchJson(`${apiUrl}/auth/logout`, {
        method: 'post'
    });
}

const authProvider = {
    login() {

    },
    async checkAuth() {
        // if (localStorage.getItem('username') !== null &&
        //     localStorage.getItem('roleId') !== null &&
        //     localStorage.getItem('roleName') !== null) {
            return Promise.resolve()
        // }
        // console.log(`checkAuth failed, back to login`);    
        // return Promise.reject();
    },
    async logout() {
        console.log(`logout!!`)
        localStorage.removeItem('username');
        localStorage.removeItem('roleId');
        localStorage.removeItem('roleName');
        return logout().then(() => {
            return '/login';
        })
    },
    getPermissions() {
        return Promise.resolve(localStorage.getItem('roleId'));
    },
    checkError(err) {
        if (err.code === UNAUTH_ERROR_CODE) {
            return Promise.reject({  redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    getIdentity() {
        try {
            const fullName = localStorage.getItem('username');
            const roleId = localStorage.getItem('roleId');
            const roleName = localStorage.getItem('roleName');
            const displayRoleName = roleName.toLowerCase() === 'systemmanager' ? 'System Manager' : 'Operator'
            return Promise.resolve({ roleId, fullName: `${fullName} | ${displayRoleName}`, roleName });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default authProvider;
