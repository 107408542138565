import { useCallback, useEffect, useState } from 'react';
import { useQuery } from './useQuery';
import { mintNft } from '../common/mintNft';
import {
    useWeb3React
} from '@web3-react/core'
import { useWeb3 } from '../hooks/useWeb3';
import { 
    metadataConfigRemote2LocalTransformer, 
    metadataConfigLocal2RemoteTransformer,
    metadataTraitsRemote2LocalTransformer,
    metadataTraitsLocal2RemoteTransformer,
    distributionRemote2LocalTransformer,
} from './transformers';


// hooks

// partner apis

export const useGetPartner = (props = {}) => {
    const [finalData, setFinalData] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        initialLoading: true,
    });
    const sendRequest = useCallback((partnerId) => {
        return rawSend({
            path: `/partner/${partnerId}`,
        });
    }, [rawSend]);

    useEffect(() => {
        if (data && data.item && data.item.id) {
            setFinalData(data.item);
        }
    }, [data]);

    // no created yet, id = undefined
    return {
        error,
        loading,
        sendRequest,
        data: finalData,
    }
}



// collection apis
export const useGetCollection = (props = {}) => {
    const [finalData, setFinalData] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        initialLoading: true,
    });
    const sendRequest = useCallback((collectionId) => {
        return rawSend({
            path: `/collection/${collectionId}`,
        });
    }, [rawSend]);

    useEffect(() => {
        if (data && data.item && data.item.id) {
            setFinalData(data.item);
        }
    }, [data]);

    // no created yet, id = undefined
    return {
        error,
        loading,
        sendRequest,
        data: finalData,
    }
}

export const useUpdateCollection = (props = {}) => {
    const [finalData, setFinalData] = useState({});
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
    });
    const sendRequest = useCallback((collectionData) => {
        return rawSend({
            method: 'post',
            path: `/collection/${collectionData.id}/update`,
            params: collectionData,
        });
    }, [rawSend]);

    useEffect(() => {
        if (data && data.item) {
            setFinalData(data.item);
        }
    }, [data]);

    // no created yet, id = undefined
    return {
        error,
        loading,
        sendRequest,
        data: finalData,
    }
}

// metadata config
export const useGetMetadataConfig = (props = {}) => {
    const [finalData, setFinalData] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Fetch meta config failed!',
        initialLoading: true,
    });
    const sendRequest = useCallback((collectionId) => {
        return rawSend({
            path: '/metadata/config',
            params: { collection_id: collectionId },
        });
    }, [rawSend]);

    useEffect(() => {
        if (data && data.item && data.item.id) {
            setFinalData(metadataConfigRemote2LocalTransformer(data.item));
        }
    }, [data]);

    // no created yet, id = undefined
    return {
        error,
        loading,
        sendRequest,
        data: finalData,
    }
}


const PAGE_SIZE = 1000;

export const useGetMetadataTraits = (props = {}) => {
    const [finalData, setFinalData] = useState(null);
    const [traitDictionary, setTraitDictionary] = useState({});
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Fetch meta traits config failed!',
        initialLoading: true,
    });

    const sendRequest = useCallback((collectionId) => {
        return rawSend({
            path: '/metadata/trait/list',
            params: { 
                collection_id: collectionId,
                page_size: PAGE_SIZE,
            },
        });
    }, [rawSend]);


    useEffect(() => {
        if (data && data.items) {
            const traitDictionaryTemp = data.items.reduce((target, { trait_key, trait_value }) => {
                target[trait_key] = target[trait_key] ? [...target[trait_key], trait_value] : [trait_value];
                return target;
            }, {});
            setTraitDictionary(traitDictionaryTemp);
            setFinalData(metadataTraitsRemote2LocalTransformer(data.items));
        }
    }, [data])

    return {
        error,
        loading,
        traitDictionary,
        data: finalData,
        sendRequest,
    };
}


export const useCreateMetadataConfig = (props = {}) => {
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Create meta config failed!',
        successMessage: 'Meta config saved!',
        needSuccessNotification: true,
    });

    const sendRequest = useCallback((params) => {
        return rawSend({
            path: `/metadata/config/create`,
            method: 'post',
            params: metadataConfigLocal2RemoteTransformer(params),
        })
    }, [rawSend]);

    if (!data || error || loading) {
        return { error, loading, data, sendRequest };
    }
    return {
        error,
        loading,
        data,
        sendRequest,
    }
}

// ========================= gen nft ======================
export const useGenerateNFT = (props = {}) => {
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Generate NFT failed',
        successMessage: 'Generate NFT success, might take a few seconds to finish',
        needSuccessNotification: true,
    });

    const sendRequest = useCallback((collectionId) => {
        return rawSend({
            path: `/metadata/generate_nft`,
            method: 'post',
            params: {
                collection_id: collectionId
            },
        })
    }, [rawSend])

    if (!data || error || loading) {
        return { error, loading, data, sendRequest };
    }
    return {
        error,
        loading,
        data,
        sendRequest,
    }
}


export const useUpdateMetadataTraits = (props = {}) => {
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Update meta traits failed',
        needSuccessNotification: true,
    });

    const sendRequest = useCallback((values) => {
        const values2 = {
            ...values,
            trait_key_value_pairs: metadataTraitsLocal2RemoteTransformer(values.items)
        };
        return rawSend({
            path: `/metadata/trait/update`,
            method: 'post',
            params: values2,
        })
    }, [rawSend])

    useEffect(() => {
        if (data) {

        }
    }, [data])

    return {
        error,
        loading,
        data,
        sendRequest,
    }
}


// ===================== nft review =====================

export const useRequestNftReview = (props = {}) => {
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Request NFT review failed',
        needSuccessNotification: true,
    });

    const sendRequest = useCallback((ids) => {
        return rawSend({
            path: `/nft/mint_review`,
            method: 'post',
            params: { ids },
        })
    }, [rawSend]);

    if (!data || error || loading) {
        return { error, loading, data, sendRequest };
    }
    return {
        error,
        loading,
        data,
        sendRequest,
    }
}

export const useApproveNftReview = (props = {}) => {
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Approve NFT review failed',
        needSuccessNotification: true,
    });

    const sendRequest = useCallback((ids) => {
        return rawSend({
            path: `/nft/mint_approve`,
            method: 'post',
            params: { ids, approve: true, },
        })
    }, [rawSend])

    if (!data || error || loading) {
        return { error, loading, data, sendRequest };
    }
    return {
        error,
        loading,
        data,
        sendRequest,
    }
}

export const useCheckNft = (props = {}) => {
    const [ finalData, setFinalData ] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Check NFT failed',
    });

    const sendRequest = useCallback((ids) => {
        return rawSend({
            path: `/nft/mint_check`,
            method: 'post',
            params: { ids },
        })
    }, [rawSend])

    useEffect(() => {
        if (data) {
            setFinalData(data);
        }
    }, [data])

    return {
        error,
        loading,
        data: finalData,
        sendRequest,
    }
}

export const useUpdateNft = (props = {}) => {
    const [ finalData, setFinalData ] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Update NFT info failed!',
    });

    const sendRequest = useCallback((ids, mint_tx) => {
        return rawSend({
            path: `/nft/mint_update`,
            method: 'post',
            params: { ids, mint_tx, success: true, },
        })
    }, [rawSend])

    useEffect(() => {
        if (data) {
            setFinalData(data);
        }
    }, [data])

    return {
        error,
        loading,
        data: finalData,
        sendRequest,
    }
}

export const useMintNft = ({ onSuccess }) => {    
    const [loading, setLoading] = useState(false);

    const web3 = useWeb3();
    const { account } = useWeb3React();

    const checkNftQuery = useCheckNft();
    const updateNftQuery = useUpdateNft();
    const { sendRequest: sendCheckNftQuery } = checkNftQuery;
    const { sendRequest: sendUpdateNftQuery } = updateNftQuery;
    const mint = useCallback(async (recipients, tokenIds, ids, contractAddress, templateId) => {
        if (ids && recipients && contractAddress && account && web3) {
            setLoading(true);
            try {
                await sendCheckNftQuery(ids);
                console.log('check nft success! minting nft');
                const tx = await mintNft(web3, recipients, tokenIds, contractAddress, account, templateId);
                console.log('mint nft success! updating nft...', tx.transactionHash);
                await sendUpdateNftQuery(ids, tx.transactionHash);
                console.log('update nft success! all done');
                onSuccess && onSuccess();
            } catch (e) {
                console.log('mint error!');
                throw e;
            } finally {
                setLoading(false);
            }
        }
    }, [web3, account, sendCheckNftQuery, sendUpdateNftQuery, onSuccess]);

    return { mint, loading }
}


// nft audit
export const useGetNftAudit = (props = {}) => {
    const [ finalData, setFinalData ] = useState([]);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        needErrorNotification: true,
        errorMessage: 'Get NFT audit logs failed',
        initialLoading: true,
    });

    const sendRequest = useCallback((id) => {
        return rawSend({
            path: `/nft/auditlogs`,
            params: { nft_id: id },
        })
    }, [rawSend]);

    useEffect(() => {
        if (data && data.items) {
            setFinalData(data.items);
        }
    }, [data]);

    return {
        error,
        loading,
        data: finalData,
        sendRequest,
    }
};

// distribution
export const useGetDistribution = (props = {}) => {
    const [ finalData, setFinalData ] = useState(null);
    const { error, loading, data, sendRequest: rawSend } = useQuery({
        ...props,
        initialLoading: true,
    });

    const sendRequest = useCallback((collectionId) => {
        return rawSend({
            path: `/metadata/distribution`,
            params: { collection_id: collectionId },
        })
    }, [rawSend]);

    useEffect(() => {
        if (data && data.item) {
            setFinalData(distributionRemote2LocalTransformer(data.item));
        }
    }, [data]);

    return {
        error,
        loading,
        data: finalData,
        sendRequest,
    }
};

