import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, Toolbar, SaveButton, required } from 'react-admin';

const PartnerEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const PartnerEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<PartnerEditToolbar />}>
            <NumberInput disabled label="ID" source="id" />
            <TextInput label="Name" source="name" validate={required()} />
            <TextInput label="Description" source="description" validate={required()} />
        </SimpleForm>
    </Edit>
);