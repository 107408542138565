import React, { useEffect, useState, } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Button, Select, MenuItem, } from '@material-ui/core';
import { Power } from '@material-ui/icons';
import styled from 'styled-components';

import useAuth from '../../hooks/useAuth';

import { defaultChainId, networkConfigs, setCachedChainId, setupNetwork, } from '../network'

const ConnectorButtonWrapper = styled.div`
  .connector-button-container {
    display: flex;
    height: 24px;
}

  .connector-button-container .connector-button-network-select {
    padding: 4px;
    padding-right: 0px;
    padding-left: 8px;
    font-size: 14px;
    background: #fff;
    margin-right: 8px;
    min-width: 180px;
    text-transform: uppercase;
  }
`

function formatAccount(account) {
  return `${account.slice(0, 6)}...${account.slice(-4)}`
}


export function ConnectorButton(props) {
  const { login, } = useAuth();
  const { account, chainId: providerChainId, } = useWeb3React();
  const [chainId, setChainId] = useState(defaultChainId);

  useEffect(() => {
    if (networkConfigs[providerChainId]) {
      setChainId(Number(providerChainId));
    }
  }, [providerChainId])

  useEffect(() => {
    setCachedChainId(chainId);
  }, [chainId])
  
  return (
    <ConnectorButtonWrapper>
      <div className="connector-button-container">
        <Select
          labelId="chain-selector-label"
          color="primary"
          variant="outlined"
          value={chainId}
          className="connector-button-network-select"
          placeholder="Select a chain"
          onChange={(e) => {
            console.log(`switch to network`, e.target.value)
            const newChainId = Number(e.target.value);
            setChainId(newChainId);
            setupNetwork(newChainId);
          }}
        >
          {
            Object.keys(networkConfigs).map(id => {
              return <MenuItem key={`network-id-${id}`} value={id}>{networkConfigs[id].name}</MenuItem>
            })
          }
        </Select>
        <div style={{
          display: 'flex',
        }}>
          <Button
            style={{
              flex: 1,
            }}
            className={props.className}
            variant="contained"
            color={`${account ? 'primary' : 'default'}`}
            startIcon={!account && <Power />}
            onClick={(e) => {
              if (!account) {
                login(chainId);
              }
              return false;
            }}
          >
            {account ? formatAccount(account) : 'Connect wallet'}
          </Button>
        </div>
      </div>
    </ConnectorButtonWrapper>
  );
}
