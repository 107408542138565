import React from 'react';
import { Link } from 'react-router-dom';

import "./index.css";
import { Box } from '@material-ui/core';
import { stringSimplify } from '../common/EthscanLink';

// example data
// "id": i,
// "collection_id": 12345,
// "external_order_id": "xxxxxx",
// "status": "Paid",
// "user_wallet_addr": "0xe07044E2d542886f456550042B9ad55fd1805acC",
// "mint_amount": 5,
// "reserved_token_ids": [1, 2, 3, 4, 5] //指定token id


export const OrderBlock = ({ 
    id,
    collection_id,
    external_order_id,
    status,
    user_wallet_addr,
    mint_amount,
    reserved_token_ids,
    email,
    user_name, }) => {
    return (
        <Box className="metadata-block">
            <div className="metadata-block-left">
                {/* <div className="metadata-item">
                    <p className="name">Order ID:</p>
                    <p className="value">{id}</p>
                </div> */}
                {/* <div className="metadata-item">
                    <p className="name">Status:</p>
                    <p className="value">{status || '-'}</p>
                </div> */}
                <div className="metadata-item">
                    <p className="name">Email:</p>
                    <p className="value">{email || '-'}</p>
                </div>
                <div className="metadata-item">
                    <p className="name">Username:</p>
                    <p className="value">{user_name || '-'}</p>
                </div>
                <div className="metadata-item">
                    <p className="name">Wallet:</p>
                    <p className="value">{stringSimplify(user_wallet_addr) || '-'}</p>
                    <Link className="view-all" to={`/order/${id}/show`}>View all</Link>
                </div>
            </div>
        </Box>
    );
};