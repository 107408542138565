import React from 'react';
import { FunctionField, } from 'react-admin';
import styled from 'styled-components';
import { chainIdNetworkConfigMap, } from '../common/network';

const StyleWrapper = styled.div`
    .empty-text {
        color: #999;
    }
`;

export function stringSimplify(str) {
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
}

export function TxLinkRaw({ record, source, chain }) {
    return <StyleWrapper>
        {
            record[source] ? <a target="_blank"
                href={`${chainIdNetworkConfigMap[chain || 'eth'].blockExplorerUrl}/tx/${record[source]}`}
                rel="noreferrer">
                {stringSimplify(record[source])}
            </a> : <span className="empty-text">None</span>
        }
    </StyleWrapper>
}

export function AddressLinkRaw({ record, source, chain }) {
    return <StyleWrapper>
        {
            record[source] ? <a 
                target="_blank" 
                href={`${chainIdNetworkConfigMap[chain || 'eth'].blockExplorerUrl}/address/${record[source]}`}
                rel="noreferrer">
                {stringSimplify(record[source])}
            </a> : <span className="empty-text">None</span>
        }
    </StyleWrapper>
}

export function TxLink({ source, title, chain, ...props }) {
    return <FunctionField
        {...props}
        sortable={false}
        label={title}
        render={record => <TxLinkRaw record={record} source={source} chain={chain} />}
    />;
}

export function AddressLink({ source, title, chain, ...props }) {
    return <FunctionField
        {...props}
        sortable={false}
        label={title}
        render={record => <AddressLinkRaw record={record} source={source} chain={chain} />}
    />;
}





