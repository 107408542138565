import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Upload from 'rc-upload';
import { useNotification } from '../hooks/useNotification';
import { downloadFile } from '../common/download';
import { apiUrl } from '../ApiProvider'

export const DownloadButton = ({ className, buttonText, url, ...buttonProps }) => {
    return (
        <div className={className}>
            <Button
                startIcon={<CloudDownload />}
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                    console.log(`=== ${apiUrl}${url}`)
                    downloadFile(`${apiUrl}${url}`);
                }}
                {...buttonProps}
            >
                {buttonText || 'DOWNLOAD METADATA FILE'}
            </Button>
        </div>
    )
}

export const UploadButton = ({ className, 
    buttonText, 
    onSuccess, 
    onStart,
    onAbort,
    onError,
    url,
    accept,
    children,
    ...buttonProps
}) => {
    const [,sendNotification] = useNotification();
    const [loading, setLoading] = useState(false);

    return (<Upload 
        className={className}
        accept={accept || 'text/csv'}
        component="div"
        onAbort={() => {
            setLoading(true);
            onAbort && onAbort();
        }}
        onStart={() => {
            setLoading(true);
            onStart && onStart();
        }}
        onSuccess={(data) => {
            setLoading(false);
            console.log(data)
            if (data.code !== 0) {
                sendNotification({ msg: data.msg, variant: 'error', });
            } else {
                sendNotification({ msg: 'Success!', variant: 'success', });
                onSuccess && onSuccess(data);
            }
        }}
        onError={(e) => {
            setLoading(false);
            if (e) {
                sendNotification({ msg: e.message, variant: 'error', });
            }
            onError && onError();
        }}
        // action={`${apiUrl}/metadata/upload?collection_id=${collectionId}`}
        action={`${apiUrl}${url}`}
    >
        {children || <Button
            startIcon={loading ? <CircularProgress /> : <CloudUpload />}
            disabled={loading}
            size="large"
            color="primary"
            variant="contained"
            {...buttonProps}
        >
            {buttonText || 'UPLOAD METADATA FILE...'} 
        </Button>
        }
    </Upload>)
}
