import { FunctionField, } from 'react-admin';
import { Tooltip } from '@material-ui/core';

const maxLength = 30;

export function LongText({ text }) {
    return <Tooltip title={text} placement="right" sx={{
        m: 1,
    }}>
        <span>{
            (text && text.length > maxLength)
                ? text.slice(0, maxLength) + '...'
                : text
            }</span>
    </Tooltip>
}

export function LongTextField(props) {
    return <FunctionField 
        {...props}
        render={record => {
            const text = record[props.source];
            return <LongText text={text} />
        }}
    />;
}