export const useUserInfo = () => {
  // const roleName = localStorage.getItem('roleName');
  const roleName = 'systemmanager';
  return { 
    username: localStorage.getItem('username'),
    roleId: localStorage.getItem('roleId'),
    roleName: localStorage.getItem('roleName'),
    isAdmin: roleName != null && roleName.toLowerCase() === 'systemmanager',
  };
};

