import React from 'react';

import {
    Pagination as DefaultPagination
} from 'react-admin';


export const Pagination = (props) => {
    return <DefaultPagination
        {...props}
        labelRowsPerPage="Rows"
    />
}