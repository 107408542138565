import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField, } from 'react-admin';
import { Link } from 'react-router-dom';
import { AddressLink } from '../common/EthscanLink';

export const OrderShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField sortable={false} label="ID" source="id" />
            <TextField sortable={false} label="Username" source="user_name" />
            <TextField sortable={false} label="Email" source="email" />
            <FunctionField sortable={false}
                label="Collection"
                render={record => <Link to={`/collection/${record.collection_id}`}>{record.collection && record.collection.name}</Link>}
            />
            <FunctionField sortable={false}
                label="Channel / OrderID"
                render={record => <div><span>{record.order_channel}</span> / {record.external_order_id}</div>}
            />
            <TextField sortable={false} label="Status" source="status" />
            <AddressLink source="user_wallet_addr" title="User wallet address" />
            <FunctionField sortable={false}
                label="Quantity / Minted"
                render={record => <div>{record.purchase_amount} / {record.minted_amount}</div>}
            />
            <TextField sortable={false} label="Reserved token IDs" source="reserved_token_ids" />
            <TextField sortable={false} label="Order Time" source="create_time" />
        </SimpleShowLayout>
    </Show>
);