import React, { useEffect, useState, } from 'react';
import { useRedirect, } from 'react-admin';
import { Stepper, Step, StepLabel, Box, Button, } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { stringify } from 'query-string';
import { useConfirm, } from 'material-ui-confirm';

import { MetadataTraitsConfig } from './MetadataTraitsConfig';
import { MetadataGenerationConfig } from './MetadataGenerationConfig';
import { PreSelection } from './PreSelection';
import { useParameter } from '../hooks/useParameter';
import { DownloadButton, UploadButton } from './Uploader';

import './index.css';
import { DistributionDetails } from '../nfts/DistributionDetails';
import { useGetDistribution } from '../api';

const steps = [
    'Traits dictionary',
    'Trait generation rules'
]


export const MetadataConfig = (props) => {
    // collection id, must be number, otherwise backend error
    const collectionId = Number(props.id);

    const redirect = useRedirect();
    const { partnerId, allParams, setCollectionId, collectionId: recordedCollectionId } = useParameter();

    useEffect(() => {
        if (!recordedCollectionId) {
            setCollectionId(collectionId);
        }
    }, [collectionId, recordedCollectionId, setCollectionId])


    // check valid
    if (!['0_1', '1_1', '2_1', '2_2'].includes(allParams.step)) {
        console.log('invalid step parameter');
        return null;
    }

    const [option, activeStep] = allParams.step.split('_').map(i => Number(i));


    // should pre selection which way to generate nfts
    if (option === 0) {
        return <PreSelection id={collectionId} handleSelect={(i) => {
            redirect(`/metadata/${collectionId}?step=${i}_${activeStep}`);
        }} />;
    }

    if (option === 1) {
        return <UploadGeneration 
            partnerId={partnerId}
            collectionId={collectionId} 
            option={option} 
            activeStep={activeStep}
        />
    }

    if (option === 2) {
        return <ConfigGeneration 
            partnerId={partnerId}
            collectionId={collectionId} 
            option={option}
            activeStep={activeStep}
        />
    }
};

export const UploadGeneration = ({ collectionId, partnerId, option, activeStep }) => {
    const redirect = useRedirect();
    return <Box className="uploader-box">
        <DownloadButton 
            className="download-button"
            url={`/metadata/download?collection_id=${collectionId}`}
        />
        <UploadButton
            url={`/metadata/upload?collection_id=${collectionId}`}
            className="upload-container"
            onSuccess={() => {
                redirect(`/nft?${stringify({
                    collection_id: collectionId,
                    partner_id: partnerId,
                })}`);
            }}
        />
    </Box>
}

export const ConfigGeneration = ({ collectionId, partnerId, option, activeStep }) => {
    const confirm = useConfirm();
    const [distribution, setDistribution] = useState(null);
    const { 
        sendRequest: sendGetDistributionRequest,
        loading: getDistributionLoading,
        data: distributionData,
    } = useGetDistribution();
    

    useEffect(() => {
        sendGetDistributionRequest(collectionId);
    }, [sendGetDistributionRequest, collectionId]);

    useEffect(() => {
        if (!getDistributionLoading && distributionData) {
            setDistribution(distributionData);
        }
    }, [getDistributionLoading, distributionData])

    const redirect = useRedirect();
    const handleNext = async () => {
        redirect(`/metadata/${collectionId}?step=${option}_${activeStep + 1}`);
    }

    const handleBack = () => {
        redirect(`/metadata/${collectionId}?step=${option}_${activeStep - 1}`);
    };

    return (
        <Box className="metadata-config-container">
            <Stepper activeStep={activeStep - 1}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Button
                className="metadata-overview-button"
                startIcon={<RemoveRedEye />}
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => {
                    distribution && confirm({
                        cancellationText: false,
                        title: 'NFT metadata overview',
                        description: <DistributionDetails
                            distribution={distribution}
                        />,
                        allowClose: true,
                        dialogProps: {
                            disableAutoFocus: true,
                        }
                    });
                }}
            >
                Metadata overview
            </Button>
            <div className="collection-edit-step-content">
                {
                    activeStep === 1 && <MetadataTraitsConfig 
                        id={collectionId}
                        activeStep={activeStep} 
                        onBack={() => {
                            redirect('/collection');
                        }} 
                        onNext={handleNext} 
                        backText="Back"
                        nextText="Save"
                    />
                }
                {
                    activeStep === 2 && <MetadataGenerationConfig
                        id={collectionId}
                        activeStep={activeStep} 
                        onBack={handleBack} 
                        onNext={() => {
                            redirect(`/nft?${stringify({
                                collection_id: collectionId,
                                partner_id: partnerId,
                            })}`);
                        }} 
                        backText="Back"
                        nextText="Save"
                    />
                }
            </div>
        </Box>
    )
};