import {
  normalTemplateAbi,
  sbtTemplateAbi,
} from './NFTContractSettings';

export const mintNft = (web3, recipients, ids, contractAddress, sender, templateId) => {
  const nftContract = new web3.eth.Contract(templateId === 1 ? normalTemplateAbi : sbtTemplateAbi, contractAddress);
  if (contractAddress && web3 && sender) {
    return nftContract.methods.batchMint(recipients, ids).send({
      from: sender,
    });
  }
  else {
      throw new Error(`env not prepared, please collect your wallet`);
  }
};
